<template>
  <div style="position:relative;z-index:2;" class="white">
    <div class="styled-background dynamic-padding">
      <div class="contact-flex">
        <div class="cont">
          <div class="text">
            <h1 class="rufina big-text">
              If Not Now, When? Let's Work Together!
            </h1>
            <p class="mt-10 darkblue--text" style="font-size: 0.9em">
              The time is always Now! Let's come together and make a dream work.
              Now! I build seamless softwares that accentuate the primary goal
              of its ideator. Send a "Hello" now...
            </p>
          </div>
        </div>
        <div class="cont">
          <div style="flex-grow:1;" class="form">
            <v-form ref="form" v-model="valid">
              <v-text-field
                solo
                flat
                class="no-radius"
                placeholder="Name"
                height="60px"
                v-model="name"
              ></v-text-field>
              <v-text-field
                solo
                flat
                placeholder="Email Address"
                class="no-radius"
                height="60px"
                :rules="[...rules.email]"
                validate-on-blur
                v-model="email"
              ></v-text-field>
              <v-textarea
                solo
                no-resize
                flat
                placeholder="Your Message"
                class="no-radius"
                :rules="[
                  rules.required('message'),
                  rules.minlength(50, 'message'),
                ]"
                counter="50"
                validate-on-blur
                v-model="message"
              ></v-textarea>
              <v-btn
                class="brand text-capitalize font-weight-normal mt-6"
                depressed
                dark
                large
                block
                tile
                :loading="sendingMail"
                height="60px"
                @click="sendMail()"
                >Send Message</v-btn
              >
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Rules from "../methods/Rules";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      valid: true,
      rules: Rules,
      sendingMail: false,
    };
  },
  methods: {
    async sendMail() {
      this.$refs.form.validate();
      if (!this.valid) return;

      this.sendingMail = true;
      try {
        init("user_6ISrOCwGjmUxvaecWZlqo");
        await emailjs.send("contact_victoronwukwe25", "template_rbumsza", {
          from_name: this.name || "Anon",
          to_name: "Victor",
          message: this.message,
          reply_to: this.email,
          subject: `New message from ${this.name || "Anon"}`,
        });
        this.sendingMail = false;
      } catch (e) {
        this.sendingMail = false;
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.styled-background {
  background-image: url("../assets/backgrounds/pink.png");
  width: 100%;
  padding-top: 3em;
  padding-bottom: 3em;
  background-repeat: repeat;
}
.contact-flex {
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 3em;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}
.cont {
  display: flex;
}
.cont .text {
  max-width: 600px;
  margin: 0 auto;
}
.cont .form {
  max-width: 600px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .styled-background {
    padding-top: 5em;
    padding-bottom: 5em;
  }
  .contact-flex {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}
@media (min-width: 929px) {
  .cont .text {
    max-width: 600px;
  }
  .cont .form {
    max-width: 500px;
    margin-left: auto;
    margin-right: 0;
  }
}
@media (min-width: 1000px) {
  .styled-background {
    padding-top: 7em;
    padding-bottom: 7em;
  }
}
</style>
