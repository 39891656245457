<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" class="icon">
    <g>
      <path
        style="fill: white"
        d="M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25C0,23.818,2.182,26,4.875,26h16.25 C23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0z M8.039,22.069H4L3.977,9.977h4.039L8.039,22.069z M5.918,8.394 H5.893c-1.318,0-2.171-0.908-2.171-2.042c0-1.159,0.879-2.041,2.222-2.041c1.344,0,2.171,0.882,2.196,2.041 C8.14,7.485,7.287,8.394,5.918,8.394z M22.042,22.07h-4.075v-6.571c0-1.588-0.421-2.671-1.842-2.671 c-1.084,0-1.671,0.731-1.955,1.437c-0.104,0.253-0.13,0.604-0.13,0.957v6.849H9.945L9.922,9.977h4.095l0.023,1.705 c0.521-0.806,1.394-1.953,3.48-1.953c2.584,0,4.521,1.688,4.521,5.317V22.07z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "linkedin",
};
</script>
<style scoped>
.icon {
  fill: currentColor;
}
</style>
