let Rules = {
  email: [
    v => !!v || 'Hi, I need your email to get back to you',
    v =>
      (!!v &&
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v.trim()
        )) ||
      "Hello, please check that there's no slight error in your email"
  ],
  minlength: (len, field) => v =>
    (v || '').length >= len
      ? true
      : len == 1
      ? field
        ? field + ' is required'
        : 'This field' + ' is required'
      : `Hello, could you please make your ${
          field ? field : 'This field'
        } at least ${len} characters long`,
  maxlength: (len, field) => v =>
    (v || '').length <= len ||
    `${field ? field : 'This field'} should contain at most ${len} characters`,
  password: [
    v => !!v || 'Please enter your password',
    v => (v && v.length >= 8) || 'Password should contain at least 8 characters'
  ],
  required: (field, cond) => v =>
    cond || !!v || `Hi, your ${field ? field : 'This field'} is required`
}

export default Rules
