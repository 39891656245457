<template>
  <div style="animation: slide-up 0.5s ease">
    <display-portfolio></display-portfolio>
    <div class="d-flex justify-center">
      <v-btn v-if="authenticated" color="brand" dark class="mt-12 mb-6"
        @click="displayAddProject = !displayAddProject">Add Project
        <v-icon>{{
          displayAddProject ? "mdi-menu-up" : "mdi-menu-down"
        }}</v-icon></v-btn>
    </div>
    <v-container grid-list-xs v-if="displayAddProject">
      <v-form ref="form" v-model="formValid">
        <v-text-field outlined required label="Project Name" :rules="[rules.required('Name')]"
          v-model="newProject.name"></v-text-field>
        <v-text-field outlined required label="Project Link" v-model="newProject.link"></v-text-field>
        <v-textarea outlined required :rules="[rules.required('Description'), rules.minlength(200)]" label="Description"
          v-model="newProject.description" counter="200"></v-textarea>
        <div id="my-strictly-unique-vue-upload-multiple-image" style="text-align: center">
          <vue-upload-multiple-image @upload-success="(formData_) => uploadImageSuccess(formData_, formData, true)"
            @before-remove="beforeRemove" @edit-image="editImage" :data-images="images.lowImages" idUpload="myIdUpload"
            editUpload="myIdEdit" :maxImage="20" class="mb-6"></vue-upload-multiple-image>
        </div>
        <v-select outlined :items="[
          'website',
          'android app',
          'ios app',
          'digital marketing',
          'data maintenance',
        ]" v-model="newProject.type" label="Type" required :rules="[rules.required('Type')]"></v-select>
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="newProject.creationDate"
          transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="newProject.creationDate" label="Creation Date" prepend-inner-icon="mdi-calendar"
              outlined readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="newProject.creationDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(newProject.creationDate)">
              OK
            </v-btn>
          </v-date-picker> </v-menu><v-select outlined multiple @change="showVal" :items="[
            'web',
            'android',
            'firebase',
            'digital marketing',
            'maintenance',
            'javascript',
            'desktop',
            'ios',
            'mobile',
            'vuejs',
            'react',
            'flutter',
            'vuetify',
            'mongodb',
            'nodejs',
            'algolia',
            'heroku',
            'zapier',
          ]" v-model="newProject.tags" :rules="[rules.minlength(2)]" label="Tags"></v-select>
        <div class="mb-5" v-if="newProject.tags.length > 0">
          <v-chip v-for="tag in newProject.tags" :key="tag" class="ml-1" color="brand lighten-4">{{ tag }}</v-chip>
        </div>
        <v-text-field outlined required label="Project Features" v-model="newProject.features"></v-text-field>
        <v-text-field outlined label="Role" v-model="newProject.role" max-width="100px"
          :rules="[rules.required('Role')]"></v-text-field>
        <v-text-field outlined label="Project Weight" v-model="newProject.weight" max-width="100px" type="number"
          :rules="[rules.required('Weight')]"></v-text-field>
        <v-text-field outlined label="Team Size" max-width="100px" v-model="newProject.teamSize" type="number"
          :rules="[rules.required('Team size')]"></v-text-field>
        <v-text-field outlined label="Notable Mentions" placeholder="seperated by commas"
          v-model="newProject.notableMentions" :rules="[rules.required('Notable mentions')]"></v-text-field>
      </v-form>
      <m-btn outlined color="var(--brand)" @click="addProject()" :loading="adding"
        :disabled="!formValid || imageCount < 3">Submit</m-btn>
    </v-container>

    <!-- Add organization -->

    <div class="d-flex justify-center">
      <v-btn v-if="authenticated" color="brand" dark class="mt-12 mb-6"
        @click="displayAddOrganization = !displayAddOrganization">Add Organization
        <v-icon>{{
          displayAddOrganization ? "mdi-menu-up" : "mdi-menu-down"
        }}</v-icon></v-btn>
    </div>
    <v-container grid-list-xs v-if="displayAddOrganization">
      <v-form ref="organizationForm" v-model="organizationFormValid">
        <v-text-field outlined required label="Organization Name" :rules="[rules.required('Name')]"
          v-model="newOrganization.name"></v-text-field>
        <v-text-field outlined required label="Organization website" v-model="newOrganization.website"></v-text-field>
        <v-textarea outlined label="Description" v-model="newOrganization.description" counter="200"></v-textarea>
        <v-textarea outlined label="Job description" v-model="newOrganization.jobDescription" counter="20"></v-textarea>
        <div id="my-strictly-unique-vue-upload-multiple-image" style="text-align: center">
          <vue-upload-multiple-image
            @upload-success="(formData) => uploadImageSuccess(formData, organizationFormData, true)"
            @before-remove="(index, done, fileList) => beforeRemove(index, done, true, fileList)" @edit-image="editImage"
            idUpload="myOrgIdUpload" editUpload="myOrgIdEdit" :maxImage="20" class="mb-6"></vue-upload-multiple-image>
        </div>
        <v-text-field outlined v-model="newOrganization.type" label="Type"></v-text-field>
        <v-menu ref="organizationStartDateMenu" v-model="organizationStartDateMenu" :close-on-content-click="false"
          :return-value.sync="newOrganization.startDate" transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="newOrganization.startDate" label="Start Date" prepend-inner-icon="mdi-calendar"
              outlined readonly v-bind="attrs" v-on="on" :rules="[rules.required('Start date')]"></v-text-field>
          </template>
          <v-date-picker v-model="newOrganization.startDate" no-title scrollable :max="new Date().toISOString()">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="organizationStartDateMenu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.organizationStartDateMenu.save(newOrganization.startDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu ref="organizationendDateMenu" v-model="organizationendDateMenu" :close-on-content-click="false"
          :return-value.sync="newOrganization.endDate" transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="newOrganization.endDate" label="End Date" prepend-inner-icon="mdi-calendar" outlined
              readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="newOrganization.endDate" no-title scrollable :min="newOrganization.startDate"
            :max="new Date().toISOString()">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="organizationendDateMenu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.organizationendDateMenu.save(newOrganization.endDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <div class="mb-5" v-if="newOrganization.tags.length > 0">
          <v-chip v-for="tag in newOrganization.tags" :key="tag" class="ml-1" color="brand lighten-4">{{ tag }}</v-chip>
        </div>
        <v-text-field outlined label="Role" v-model="newOrganization.role" max-width="100px"
          :rules="[rules.required('Role')]"></v-text-field>
        <v-text-field outlined label="Organization Weight" v-model="newOrganization.weight" max-width="100px"
          type="number" :rules="[rules.required('Weight')]"></v-text-field>
        <v-text-field outlined label="Team Size" max-width="100px" v-model="newOrganization.size" type="number"
          :rules="[rules.required('Team size')]"></v-text-field>
        <v-textarea outlined label="Notable Mentions" placeholder="seperated by commas"
          v-model="newOrganization.notableMentions" :rules="[rules.required('Notable mentions')]"></v-textarea>
      </v-form>
      <m-btn outlined color="var(--brand)" @click="addOrganization()" :loading="adding"
        :disabled="!organizationFormValid">Submit</m-btn>
    </v-container>
  </div>
</template>
<script>
import Button from "../components/Button";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import FormData from "form-data";
import Compressor from "compressorjs";
import DisplayPortfolio from "../components/DisplayPortfolio";

export default {
  components: {
    "m-btn": Button,
    VueUploadMultipleImage,
    "display-portfolio": DisplayPortfolio,
  },
  data() {
    return {
      newProject: {
        name: "",
        link: "",
        description: "",
        type: "",
        tags: [],
        creationDate: null,
        features: "",
        weight: "",
        role: "",
        teamSize: "",
        notableMentions: "",
      },
      newOrganization: {
        name: "",
        website: "",
        description: "",
        jobDescription: "",
        type: "",
        tags: [],
        startDate: new Date().toISOString().slice(0, 10),
        endDate: null,
        weight: "",
        role: "",
        size: "",
        notableMentions: "",
      },
      displayAddProject: false,
      displayAddOrganization: false,
      adding: false,
      menu: false,
      organizationStartDateMenu: false,
      organizationendDateMenu: false,
      images: { lowImages: [], highImages: [] },
      organizationImages: { lowImages: [], highImages: [] },
      formData: new FormData(),
      organizationFormData: new FormData(),
      formValid: false,
      organizationFormValid: false,
      rules: {
        required: (field) => (v) => !!v || field + " is required",
        minlength: (min) => (v) =>
          (v && v.length >= min) ||
          `Field has to have a minimum of ${min} characters`,
      },
      imageCount: 0,
      organizationImageCount: 0,
    };
  },
  methods: {
    async addProject() {
      let project = this.newProject;
      this.adding = true;
      this.formData.set("name", this.newProject.name);
      this.formData.set("description", this.newProject.description);
      if (project.link !== "") this.formData.set("link", this.newProject.link);
      this.formData.set("creationDate", this.newProject.creationDate);
      this.formData.set("type", this.newProject.type);
      this.formData.set("weight", this.newProject.weight);
      this.formData.set("role", this.newProject.role);
      this.formData.set("teamSize", this.newProject.teamSize);
      if (project.features !== "")
        this.formData.set("features", this.newProject.features);
      if (project.notableMentions) {
        let mentions = project.notableMentions.split(",");
        mentions.forEach((mention) => {
          this.formData.append("notableMentions", mention.trim());
        });
      }
      if (this.images.lowImages.length) {
        for (let i = 0; i < this.images.lowImages.length; i++) {
          const lowImage = this.images.lowImages[i];
          const highImage = this.images.highImages[i];
          this.formData.append("lowImages", lowImage, lowImage.name);
          this.formData.append("highImages", highImage, highImage.name);
        }
      }
      try {
        await this.$store.dispatch("projects/uploadProject", this.formData);
        this.adding = false;
        this.$refs.form.reset();
        this.formData.delete("tags");
        this.images = [];
      } catch (e) {
        this.adding = false;
      }
    },

    async addOrganization() {
      let organization = this.newOrganization;
      this.adding = true;
      this.organizationFormData.set("name", this.newOrganization.name);
      if (organization.description) this.organizationFormData.set("description", this.newOrganization.description);
      if (organization.jobDescription) this.organizationFormData.set("jobDescription", this.newOrganization.jobDescription);
      if (organization.website) this.organizationFormData.set("website", this.newOrganization.website);
      this.organizationFormData.set("startDate", this.newOrganization.startDate);
      if (organization.endDate) this.organizationFormData.set("endDate", this.newOrganization.endDate);
      if (organization.type) this.organizationFormData.set("type", this.newOrganization.type);
      this.organizationFormData.set("weight", this.newOrganization.weight);
      this.organizationFormData.set("role", this.newOrganization.role);
      this.organizationFormData.set("size", this.newOrganization.size);
      if (organization.features)
        this.organizationFormData.set("features", this.newOrganization.features);
      if (organization.notableMentions) {
        let mentions = organization.notableMentions.split("\n");
        mentions.forEach((mention) => {
          const mention_ = mention.trim();
          if (mention_) {
            this.organizationFormData.append("notableMentions", mention_);
          }
        });
      }
      if (this.organizationImages.lowImages.length) {
        for (let i = 0; i < this.organizationImages.lowImages.length; i++) {
          const lowImage = this.organizationImages.lowImages[i];
          const highImage = this.organizationImages.highImages[i];
          this.organizationFormData.append("lowImages", lowImage, lowImage.name);
          this.organizationFormData.append("highImages", highImage, highImage.name);
        }
      }
      try {
        await this.$store.dispatch("organizations/uploadOrganization", this.organizationFormData);
        this.adding = false;
        this.$refs.organizationForm.reset();
        this.organizationImages = { lowImages: [], highImages: [] };
        this.organizationFormData = new FormData();
      } catch (e) {
        this.adding = false;
        this.organizationFormData = new FormData();
      }
    },
    uploadImageSuccess(formData, appendFormData, isOrganization) {
      this.compress(formData, appendFormData, isOrganization);
      if (!isOrganization) {
        this.imageCount++;
      } else {
        this.organizationImageCount++;
      }
    },
    beforeRemove(index, done, isOrganization, fileList) {
      const r = confirm("remove image");
      if (r == true) {
        const appendImage = isOrganization ? this.organizationImages : this.images;
        this[isOrganization ? "organizationImageCount" : "imageCount"]--
        const deletedImageName = fileList[index].name;
        this[isOrganization ? "organizationImages" : "images"].lowImages = appendImage.lowImages.filter(image => image.name !== deletedImageName)
        this[isOrganization ? "organizationImages" : "images"].highImages = appendImage.highImages.filter(image => image.name !== deletedImageName)
        done();
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
    compress(formData, appendFormData, isOrganization) {
      const img = formData.get("file");
      const appendImage = isOrganization ? this.organizationImages : this.images;
      new Compressor(img, {
        quality: 1,
        maxWidth: 120,
        convertSize: 5000000,
        success: (result) => {
          appendImage.lowImages.push(result);
        },
        error(err) {
          console.log(err);
        },
      });
      new Compressor(img, {
        quality: 1,
        maxWidth: 1300,
        convertSize: 5000000,
        success: (result) => {
          appendImage.highImages.push(result);
        },
        error(err) {
          console.log(err);
        },
      });
    },
    showVal(tags) {
      this.formData.delete("tags");
      for (let tag of tags) {
        this.formData.append("tags", tag);
      }
    },
  },
  computed: {
    authenticated() {
      return this.$store.getters["users/authenticated"];
    },
  },
};
</script>
