<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="icon">
    <g>
      <path
        style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans"
        d="M 38 0 L 38 4 L 43.1875 4 L 34 13.1875 L 31.40625 10.59375 L 30 9.15625 L 28.59375 10.59375 L 18 21.1875 L 15.40625 18.59375 L 14 17.15625 L 12.59375 18.59375 L 0.59375 30.59375 L 3.40625 33.40625 L 14 22.8125 L 16.59375 25.40625 L 18 26.84375 L 19.40625 25.40625 L 30 14.8125 L 32.59375 17.40625 L 34 18.84375 L 35.40625 17.40625 L 46 6.8125 L 46 12 L 50 12 L 50 2 L 50 0 L 48 0 L 38 0 z M 44 15 L 44 50 L 48 50 L 48 15 L 44 15 z M 30 20 L 30 50 L 34 50 L 34 20 L 30 20 z M 37 20 L 37 50 L 41 50 L 41 20 L 37 20 z M 23 24 L 23 50 L 27 50 L 27 24 L 23 24 z M 16 30 L 16 50 L 20 50 L 20 30 L 16 30 z M 9 31 L 9 50 L 13 50 L 13 31 L 9 31 z M 2 38 L 2 50 L 6 50 L 6 38 L 2 38 z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "analytics",
};
</script>
<style scoped>
.icon {
  fill: currentColor;
}
</style>
