<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" class="icon">
    <g>
      <path
        d="M23.934,18.947c-0.598,1.324-0.884,1.916-1.652,3.086c-1.073,1.634-2.588,3.673-4.461,3.687 c-1.666,0.014-2.096-1.087-4.357-1.069c-2.261,0.011-2.732,1.089-4.4,1.072c-1.873-0.017-3.307-1.854-4.381-3.485 C1.68,17.663,1.363,12.301,3.219,9.448c1.313-2.023,3.391-3.211,5.342-3.211c1.987,0,3.236,1.092,4.879,1.092 c1.594,0,2.565-1.095,4.863-1.095c1.738,0,3.576,0.947,4.889,2.581C18.896,11.169,19.594,17.305,23.934,18.947z M16.559,4.408 c0.836-1.073,1.47-2.587,1.24-4.131c-1.364,0.093-2.959,0.964-3.891,2.092c-0.844,1.027-1.544,2.553-1.271,4.029 C14.125,6.446,15.665,5.559,16.559,4.408z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "apple",
};
</script>
<style scoped>
.icon {
  fill: currentColor;
}
</style>
