<template>
  <v-hover v-slot:default="{ hover }">
    <div v-if="!disabled" id="button-main" :class="bodyClasses" :style="{
      backgroundColor: outlined ? 'transparent' : color,
      color: !hover
        ? outlined
          ? color
          : dark
            ? 'white'
            : 'var(--brand)'
        : outlined && color !== 'white'
          ? 'white'
          : color == 'white'
            ? 'var(--brand)'
            : dark
              ? 'white'
              : 'var(--text-primary)',
      border: outlined ? `1px solid ${color || 'var(--brand)'}` : 'none',
      borderRadius: tile ? '0' : '4px',
    }">
      <button :class="[width ? 'px-0' : '', ...classes]" v-ripple @click="emitClicked()" :style="{
        backgroundImage: outlined
          ? `linear-gradient(${color}, ${color})`
          : color !== 'white'
            ? 'linear-gradient(#E44239,#E44239)'
            : 'linear-gradient(rgba(0,0,0,0.05), rgba(0,0,0,0.05))',
        backgroundSize: hover ? '100% 100%' :
          animationPosition == 'left' || animationPosition == 'right'
            ? '0 100%'
            : '100% 0',
        backgroundPosition: animationPosition,
        width: width ? width : '',
        borderRadius: tile ? '0' : '4px',
        ...styles,
      }">
        <div id="slot" :class="{ 'hide-slot': loading }">
          <slot />
        </div>
        <div style="position:absolute;top: 0;bottom:0;left:0;right:0;display:flex">
          <v-progress-circular v-show="loading" :width="2" :size="20" indeterminate class="mx-auto my-auto"
            :color="hover ? 'white' : color">
          </v-progress-circular>
        </div>
      </button>
    </div>
  </v-hover>
</template>
<script>
export default {
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "var(--brand)",
    },
    tile: {
      type: Boolean,
      default: false,
    },
    animationPosition: {
      type: String,
      default: "left",
    },
    loading: Boolean,
    classes: { Array, default: () => [] },
    styles: Object,
    width: String || Number,
    bodyClasses: { Array, default: () => [] },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClicked() {
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
button {
  padding: 0.8rem 3rem;
  position: relative;
  z-index: 2;
  /* transition: background-size 0.4s cubic-bezier(0.68, 1.43, 0.53, 0.85); */
  transition: background-size 0.5s cubic-bezier(0.45, 1.71, 0.56, 0.61);
  display: flex;
}

#button-main {
  position: relative;
  display: inline-flex;
  transition: all 0.6s ease;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  justify-items: center;
}

button {
  outline: none !important;
}

#slot {
  display: flex;
  justify-content: center;
  width: 100%;
}

.hide-slot {
  opacity: 0;
}
</style>
