<template>
  <div class="main top d-flex align-center">
    <div class="header dynamic-padding">
      <div
        style="
          height: 100%;
          display: flex;
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
        "
      >
        <div class="house">
          <h1
            style="position: relative; z-index: 2"
            class="big-text white--text"
          >
            {{ page.name }}
          </h1>
          <router-link :to="'/'" class="no-deco ptl" style="font-size: 1.2em"
            >Home</router-link
          >
          <span v-if="$route.name == 'project'">
            <span class="mx-3 stl" style="font-size: 1.2em">/</span>
            <router-link
              to="/portfolio"
              class="no-deco ptl"
              style="font-size: 1.2em"
              >Portfolio</router-link
            >
          </span>
          <span class="mx-3 stl" style="font-size: 1.2em">/</span>
          <router-link
            exact
            :to="$route.path"
            class="no-deco ptl"
            style="font-size: 1.2em"
            >{{ page.routeName }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="background"></div>
  </div>
</template>
<script>
export default {
  computed: {
    page() {
      let route = this.$route;
      let name = this.$route.name;

      if (name === "about") return { name: "About Me", routeName: "About" };
      else if (name === "contact")
        return { name: "Contact Me", routeName: "Contact" };
      else if (name === "portfolio")
        return { name: "My Portfolio", routeName: "Portfolio" };
      else if (name === "services")
        return { name: "My Services", routeName: "Services" };
      else if (name === "project")
        return {
          name: "My Project",
          routeName: route.params.title,
        };
      else if (name === "consult")
        return { name: "Consult Me", routeName: "Consult" };
      else if (name === "hire") return { name: "Hire Me", routeName: "Hire" };
      else if (name === "login") return { name: "Login", routeName: "Login" };
      else if (name === "signup")
        return { name: "Signup", routeName: "Signup" };
      else if (name === "job-profile")
        return { name: "Job Profile", routeName: "Job Profile" };
      else return { name: "Uh oh!", routeName: "Uh oh!" };
    },
  },
};
</script>
<style scoped>
.top {
  position: relative;
  position: sticky;
  top: 0;
  margin-top: 0px;
  z-index: 2;
  width: 100%;
  min-height: 65vh;
  min-height: calc(var(--vh, 1vh) * 65);
  background: linear-gradient(
    90deg,
    rgba(40, 20, 50, 0.7),
    rgba(40, 20, 50, 0.6)
  );
}
.header {
  width: 100%;
  padding: 2em 3em;
  position: relative;
  display: flex;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
  background: url("../assets/backgrounds/top-display.jpg");
  background-position: center;
  background-size: cover;
  filter: blur(1px);
}

.house {
  margin: auto 0;
}
</style>
