import Vue from "vue";
import Vuex from "vuex";
import snackbar from "./snackbar";
import projects from "./projects";
import organizations from "./organizations";
import jobs from "./resume";
import users from "./users";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["users"]
})

const store = new Vuex.Store({
  modules: {
    snackbar: snackbar,
    projects: projects,
    organizations: organizations,
    jobs: jobs,
    users: users,
  },
  plugins: [vuexLocal.plugin]
});

export default store;
