<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon">
    <g>
      <path
        d="M 5 3 C 3.9 3 3 3.9 3 5 L 3 19 C 3 20.1 3.9 21 5 21 L 19 21 C 20.1 21 21 20.1 21 19 L 21 13 L 19 11 L 19 19 L 5 19 L 5 5 L 13 5 L 11 3 L 5 3 z M 14 3 L 16.65625 5.65625 L 9.15625 13.15625 L 10.84375 14.84375 L 18.34375 7.34375 L 21 10 L 21 3 L 14 3 z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "external-link",
};
</script>
<style scoped>
.icon {
  fill: currentColor;
}
</style>
