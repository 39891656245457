import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import router from "./Router";
import Vuex from "vuex";
import store from "./store/main";
import { VueMasonryPlugin } from "vue-masonry";
import * as VueGoogleMaps from "vue2-google-maps";
import "./plugins/axios";

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueMasonryPlugin);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAyRibCFTcep0CDha6TlLWq3gRsp2T3Xro",
    libraries: "places",
  },
});

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
