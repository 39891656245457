<template>
  <div height="100%" class="service-body">
    <div class="red-background"></div>
    <div style="position: relative; z-index: 2">
      <v-card-title>
        <div class="d-flex flex-column" style="width:100%">
          <v-icon
            color="brand"
            class="designed-icon transit mx-auto"
            size="2.3em"
            >{{ service.icon }}</v-icon
          >
          <h3 class="transit service-title" style="text-align:center">
            {{ service.name }}
          </h3>
        </div>
      </v-card-title>
      <v-card-text style="text-align:center">
        <span class="transit service-text">
          {{ service.text }}
        </span></v-card-text
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    service: Object,
  },
};
</script>

<style scoped>
.service-body {
  padding: 2em 0;
  background-color: transparent;
  transition-duration: 2s;
  border-radius: 6px;
  position: relative;
  transition: border 1s ease;
  border: 1px solid rgba(0, 0, 255, 0.1);
}
.red-background {
  transition: height 0.6s cubic-bezier(0.5, 0.27, 0.4, 0.97);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0%;
  border-radius: 6px;
  background-color: var(--brandred);
}
.transit {
  transition: all 0.6s ease;
}
.designed-icon {
  padding: 0.6em;
  border-radius: 50%;
  margin-bottom: 1em;
  background-color: rgba(255, 0, 0, 0.05);
}
.service-title {
  color: var(--darkblue-text);
}
.service-text {
  color: var(--darkblue-text);
}
@media (min-width: 600px) {
  .service-body:hover .red-background {
    height: 100%;
  }
  .service-body:hover {
    border: 1px solid transparent;
  }
  .service-body:hover .designed-icon {
    background-color: rgba(255, 255, 255, 1);
  }
  .service-body:hover .service-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .service-body:hover .service-title {
    color: white;
  }
}
</style>
