<template>
  <div class="services-display">
    <service
      v-for="i in $route.name=='home' ? 3 : 5"
      :key="i"
      :service="services[i-1]"
    ></service>
  </div>
</template>
<script>
import service from "./Service";
export default {
  components: {
    service,
  },
  data() {
    return {
      services: [
        {
          name: "Software Maintenance",
          text:
            "I Maintain software products to improve flexibility, maintainability, and performance. Implement bug fixes, restructure data, and update code structure.",
          icon: "$vuetify.icons.maintenance",
        },
        {
          name: "Web Services",
          text:
            "I Create simplified and responsive single page and server-side-rendered frontend Web applications with maintainable and flexible backend structures.",
          icon: "$vuetify.icons.web",
        },
        {
          name: "Android Development",
          text:
            "I Build Android apps with unique and appealing designs. Well synced with the android os gesture-based navigation tools. Ensure proper UI/UX.",
          icon: "$vuetify.icons.android",
        },
        {
          name: "IOS Development",
          text:
            "I Build IOS apps with unique and appealing designs. Well synced with the apple os gesture-based navigation tools. Ensure proper UI/UX.",
          icon: "$vuetify.icons.apple",
        },
        {
          name: "Digital Marketing",
          text:
            "I Promote brands on various platforms. Broaden funnels. Reach out to targeted customers. Create highly effective adverts. Ensure adequate Search Engine Optimization.",
          icon: "$vuetify.icons.analytics",
        },
      ],
    };
  },
};
</script>
<style scoped>
.services-display {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
  grid-row-gap: 3em;
  grid-column-gap: 2em;
}
</style>
