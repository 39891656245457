<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="icon">
    <g>
      <path
        style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans"
        d="M 25 12 C 11.667228 12 1.25 24.34375 1.25 24.34375 A 1.0001 1.0001 0 0 0 1.25 25.65625 C 1.25 25.65625 11.667228 38 25 38 C 38.332772 38 48.75 25.65625 48.75 25.65625 A 1.0001 1.0001 0 0 0 48.75 24.34375 C 48.75 24.34375 38.332772 12 25 12 z M 25 14 C 27.627272 14 30.141915 14.544587 32.46875 15.375 C 34.032931 17.140338 35 19.450427 35 22 C 35 27.535732 30.535732 32 25 32 C 19.464268 32 15 27.535732 15 22 C 15 19.45074 15.935707 17.139242 17.5 15.375 C 19.834652 14.538846 22.362198 14 25 14 z M 14.1875 16.84375 C 13.439134 18.407614 13 20.155051 13 22 C 13 28.616268 18.383732 34 25 34 C 31.616268 34 37 28.616268 37 22 C 37 20.163179 36.580282 18.404914 35.84375 16.84375 C 41.492764 19.714987 45.555865 23.87765 46.59375 25 C 44.969234 26.756721 35.970973 36 25 36 C 14.029027 36 5.0307657 26.756721 3.40625 25 C 4.4456392 23.876024 8.5256535 19.715345 14.1875 16.84375 z M 25 17 C 22.238576 17 20 19.238576 20 22 C 20 24.761424 22.238576 27 25 27 C 27.761424 27 30 24.761424 30 22 C 30 19.238576 27.761424 17 25 17 z"
        overflow="visible"
        enable-background="accumulate"
        font-family="Bitstream Vera Sans"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "eye",
};
</script>
<style scoped>
.icon {
  fill: currentColor;
}
</style>
