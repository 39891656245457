import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import eye from '../svg-icons/eye'
import android from '../svg-icons/android'
import web from '../svg-icons/web'
import link from '../svg-icons/external-link'
import home from '../svg-icons/home'
import phone from '../svg-icons/phone'
import mail from '../svg-icons/mail'
import service from '../svg-icons/service'
import creation from '../svg-icons/creation'
import maintenance from '../svg-icons/maintenance'
import analytics from '../svg-icons/analytics'
import apple from '../svg-icons/apple'
import github from '../svg-icons/github'
import linkedin from '../svg-icons/linkedin'

Vue.use(Vuetify)

const options = {
  icons: {
    iconfont: 'mdi',
    values: {
      eye: {
        component: eye
      },
      android: {
        component: android
      },
      web: {
        component: web
      },
      'external-link': {
        component: link
      },
      home: {
        component: home
      },
      phone: {
        component: phone
      },
      mail: {
        component: mail
      },
      service: {
        component: service
      },
      creation: {
        component: creation
      },
      maintenance: {
        component: maintenance
      },
      analytics: {
        component: analytics
      },
      apple: {
        component: apple
      },
      github: {
        component: github
      },
      linkedin: {
        component: linkedin
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#EB5B52',
        brand: '#EB5B52',
        accent: '#FF9800',
        darkblue: '#000A2D',
        lighterblue: '#10285D',
        lightblue: '#002D5B',
        brandred: '#FF3D1C'
      }
    }
  }
}

export default new Vuetify(options)
