import Vue from "vue";
import Router from "vue-router";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Project from "./pages/Project";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Resume from "./pages/RevisedResume"

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: Home,
      name: "home",
    },
    {
      path: "/about",
      component: About,
      name: "about",
    },
    {
      path: "/contact",
      component: Contact,
      name: "contact",
      props: {
        type: "contact",
      },
    },
    {
      path: "/consult",
      component: Contact,
      name: "consult",
      props: {
        type: "consult",
      },
    },
    {
      path: "/hire",
      component: Contact,
      name: "hire",
      props: {
        type: "hire",
      },
    },
    {
      path: "/services",
      component: Services,
      name: "services",
    },
    {
      path: "/portfolio",
      component: Portfolio,
      name: "portfolio",
    },
    {
      path: "/projects/:title",
      component: Project,
      name: "project",
    },
    {
      path: "/shunit",
      component: Login,
      name: "login",
    },
    {
      path: "/shunam",
      component: Signup,
      name: "signup",
    },
    {
      path: "/job-profile",
      component: Resume,
      name: "job-profile",
    },
  ],
  // scrollBehavior(to) {
  //   if (to.name == "contact" && to.query.h) return;
  //   return { x: 0, y: 0 };
  // },
});
