<template>
  <div class="d-flex justify-center">
    <div style="max-width:600px">
      <v-text-field
        name="email"
        label="Email"
        outlined
        v-model="email"
      ></v-text-field>
      <v-text-field
        name="password"
        label="Password"
        outlined
        v-model="password"
        type="password"
      ></v-text-field>
      <v-btn :loading="loading" color="brand" dark @click="signup()"
        >submit</v-btn
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    async signup() {
      this.loading = true;
      try {
        await this.$store.dispatch("users/signup", {
          email: this.email.toLowerCase(),
          password: this.password,
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  }
};
</script>
