const axios = require("axios");

const URL = "jobs";
// const LocalURL = 'http://localhost:3000/api/jobs/'

export default {
  namespaced: true,
  state: {
    projects: [],
    organizations: [],
  },
  mutations: {
    setProjects: (state, projects) => {
      state.projects = projects;
    },
    setOrganizations: (state, organizations) => {
      state.organizations = organizations;
    },
  },
  getters: {
    projects: (state) => state.projects,
    organizations: (state) => state.organizations,
  },
  actions: {
    async sendResume(commit, payload) {
      try {
        let res = await axios.post(`${URL}/send-mail`, payload);
        return res.data;
      } catch (err) {
        console.error(err);
      }
    },
    async downloadResume(commit, payload) {
      try {
        const res = await axios({
          method: "post",
          url: `${URL}/download-resume${
            !payload.coverLetter
              ? "?type=resume"
              : !payload.resume
              ? "?type=cover-letter"
              : ""
          }`,
          responseType: "blob",
          data: payload,
        });
        const both = payload.resume && payload.coverLetter;
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${
            both
              ? `Job Application for ${payload.companyName}.zip`
              : payload.resume
              ? "Curriculum Vitae.pdf"
              : `Cover Letter for ${payload.companyName}.pdf`
          }`
        );
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
      }
    },
    async fetchProjects({ commit }) {
      try {
        let res = await axios.get(URL + "/projects");
        commit("setProjects", res.data);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchOrganizations({ commit }) {
      try {
        let res = await axios.get(URL + "/organizations");
        commit("setOrganizations", res.data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
