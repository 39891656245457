<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="icon">
    <g>
      <path
        style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans"
        d="M 5 2 C 3.3545455 2 2 3.3545455 2 5 L 2 35 C 2 36.645455 3.3545455 38 5 38 L 19 38 L 19 40 L 10 40 A 1.0001 1.0001 0 0 0 9.90625 40 A 1.0001 1.0001 0 0 0 9.21875 40.375 L 4.21875 46.375 A 1.0001 1.0001 0 0 0 5 48 L 45 48 A 1.0001 1.0001 0 0 0 45.78125 46.375 L 40.78125 40.375 A 1.0001 1.0001 0 0 0 40 40 L 31 40 L 31 38 L 45 38 C 46.645455 38 48 36.645455 48 35 L 48 5 C 48 3.3545455 46.645455 2 45 2 L 5 2 z M 5 4 L 45 4 C 45.554545 4 46 4.4454545 46 5 L 46 35 C 46 35.554545 45.554545 36 45 36 L 31 36 L 19 36 L 5 36 C 4.4454545 36 4 35.554545 4 35 L 4 5 C 4 4.4454545 4.4454545 4 5 4 z M 6 6 L 6 7 L 6 33 L 6 34 L 7 34 L 43 34 L 44 34 L 44 33 L 44 7 L 44 6 L 43 6 L 7 6 L 6 6 z M 8 8 L 42 8 L 42 32 L 8 32 L 8 8 z M 19.28125 11.28125 L 11.28125 19.28125 L 10.59375 20 L 11.28125 20.71875 L 19.28125 28.71875 L 20.71875 27.28125 L 13.4375 20 L 20.71875 12.71875 L 19.28125 11.28125 z M 30.71875 11.28125 L 29.28125 12.71875 L 36.5625 20 L 29.28125 27.28125 L 30.71875 28.71875 L 38.71875 20.71875 L 39.40625 20 L 38.71875 19.28125 L 30.71875 11.28125 z M 21 38 L 29 38 L 29 42 L 21 42 L 21 38 z M 10.46875 42 L 19 42 L 19 43 L 19 44 L 20 44 L 30 44 L 31 44 L 31 43 L 31 42 L 39.53125 42 L 42.875 46 L 7.125 46 L 10.46875 42 z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "creation",
};
</script>
<style scoped>
.icon {
  fill: currentColor;
}
</style>
