<template>
  <div class="top">
    <div class="header">
      <div style="width: 100%; min-height: 100%" class="dynamic-padding">
        <div
          style="
            max-width: 1200px;
            margin: 0 auto;
            z-index: 3;
            position: relative;
            min-height: 100%;
            display: flex;
            flex-direction: column;
          "
        >
          <div class="mx-auto" style="font-size: 2em">
            <div
              class="font-weight-bold white--text align shadow-text"
              style="line-height: 0"
            >
              VICTOR'S
            </div>
            <div class="font-weight-bold rufina white--text shadow-text align">
              DESIGNS
            </div>
          </div>
          <h1 class="rubik white--text align mt-10 mail-txt font-weight-normal">
            victoronwukwe25@gmail.com
          </h1>
          <p class="ptl mt-6 align">#27 Okekem Lane, Off Apamini, Woji</p>
          <p class="ptl mt-n4 align">Port Harcourt, Nigeria - 500272</p>
          <div class="mt-auto mb-6 footer-bottom">
            <span class="htl font-weight-light"
              >Copyright ©2020 - Victor Onwukwe</span
            >
            <div class="social">
              <a
                href="https://github.com/VictorOnwukwe"
                target="_blank"
              >
                <v-icon color="grey">$vuetify.icons.github</v-icon>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/victor-onwukwe-6b0869181/"
                class="ml-5"
              >
                <v-icon color="grey">mdi-linkedin</v-icon>
              </a>
              <a
                target="_blank"
                href="https://web.facebook.com/victor.onwukwe.9822"
                class="ml-5"
              >
                <v-icon color="grey">mdi-facebook</v-icon>
              </a>
              <a
                target="_blank"
                href="https://wa.me/2348132131972"
                class="ml-5"
              >
                <v-icon color="grey">mdi-whatsapp</v-icon>
              </a>
              <a
                target="_blank"
                href="https://twitter.com/victoronwukwe_"
                class="ml-5"
              >
                <v-icon color="grey">mdi-twitter</v-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-btn
      outlined
      v-if="$store.getters['users/authenticated']"
      style="
        position: absolute;
        top: 1em;
        right: 1em;
        opacity: 0;
        cursor: default;
      "
      @click="logout()"
      >Logout</v-btn
    >
    <v-btn
      outlined
      v-if="!$store.getters['users/authenticated']"
      style="
        position: absolute;
        top: 1em;
        right: 1em;
        opacity: 0;
        cursor: default;
      "
      @click="$router.push('/shunit')"
      >Login</v-btn
    >
  </div>
</template>
<script>
export default {
  methods: {
    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    logout() {
      this.$store.dispatch("users/logout");
    },
  },
};
</script>
<style scoped>
.top {
  position: relative;
}
.header {
  background-color: rgba(15, 18, 50, 0.95);
  min-height: 65vh;
  width: 100%;
  padding-top: 5em;
  position: relative;
  background-size: cover;
  background-position: 100% 100%;
  display: flex;
}
.social > a {
  background: none;
}
h1 {
  font-size: 1.7em;
}
.align {
  text-align: center;
}
.footer-bottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.footer-bottom div {
  margin-bottom: 0.8em;
}
.mail-txt {
  font-size: 1.55em;
  font-weight: normal;
}
@media (min-width: 580px) {
  .mail-txt {
    font-size: 2.2em;
  }
  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 75px;
  }
  .footer-bottom div {
    margin-bottom: 0;
  }
}
</style>
