<template>
  <div>
    <div class="d-flex flex-column align-center mb-12 position:relative">
      <div class="d-flex align-center flex-column">
        <v-icon size="2.3em" color="brand lighten-4"
          >$vuetify.icons.service</v-icon
        >
        <h1
          class="rubik font-weight-normal"
          style="font-weight:normal;position:relative;z-index:2"
        >
          My Services
        </h1>
      </div>
    </div>
    <services></services>
    <display-portfolio style="margin-top:5em"></display-portfolio>
  </div>
</template>

<script>
import DisplayServices from "../components/DisplayServices";
import DisplayPortfolio from "../components/DisplayPortfolio";
export default {
  components: {
    services: DisplayServices,
    "display-portfolio": DisplayPortfolio,
  },
};
</script>
