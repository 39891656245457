<template>
  <div
    style="
  animation: slide-up 0.5s ease;"
  >
    <services></services>
  </div>
</template>
<script>
import DisplayServices from "../components/DisplayServices";
export default {
  components: {
    services: DisplayServices,
  },
  data() {
    return {};
  },
};
</script>
