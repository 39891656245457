<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="icon">
    <g>
      <path
        style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;isolation:auto;mix-blend-mode:normal;fill:white"
        d="M 25 1.7402344 L 1.390625 19.841797 A 1.0008433 1.0008433 0 0 0 2.609375 21.429688 L 4 20.363281 L 4 48 L 17.832031 48 A 1.0001 1.0001 0 0 0 18.158203 48 L 31.832031 48 A 1.0001 1.0001 0 0 0 32.158203 48 L 46 48 L 46 20.363281 L 47.390625 21.429688 A 1.0008433 1.0008433 0 0 0 48.609375 19.841797 L 42 14.775391 L 42 5 L 36 5 L 36 10.173828 L 25 1.7402344 z M 25 4.2597656 L 38 14.228516 L 38 7 L 40 7 L 40 15.761719 L 44.011719 18.837891 A 1.0001 1.0001 0 0 0 44 19 L 44 46 L 33 46 L 33 25 L 17 25 L 17 46 L 6 46 L 6 19 A 1.0001 1.0001 0 0 0 5.9882812 18.839844 L 25 4.2597656 z M 19 27 L 31 27 L 31 46 L 19 46 L 19 27 z M 28 36 A 1 1 0 0 0 27 37 A 1 1 0 0 0 28 38 A 1 1 0 0 0 29 37 A 1 1 0 0 0 28 36 z"
        font-weight="400"
        font-family="sans-serif"
        white-space="normal"
        overflow="visible"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "home",
};
</script>
<style scoped>
.icon {
  fill: currentColor;
}
</style>
