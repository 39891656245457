<template>
  <v-app id="app">
    <v-main>
      <toolbar
        style="z-index:6;background:inherit"
        @toggle="overlay = !overlay"
        :dark="dark"
      ></toolbar>
      <div style="width:100%">
        <top-display
          v-if="$route.name !== 'home'"
          style="z-index:1"
        ></top-display>
        <home-display v-else style="z-index:1"></home-display>
        <div
          class="router-body dynamic-padding elevation-12"
          :class="$route.name == 'home' ? 'home-margin' : 'other-margin'"
          style=""
          id="main-route"
        >
          <router-view style="max-width:1200px; margin: 0 auto"></router-view>
        </div>
      </div>
      <foot-contact
        v-show="
          $route.name == 'home' ||
            $route.name == 'about' ||
            $route.name == 'services' ||
            $route.name == 'project'
        "
      ></foot-contact>
      <Footer></Footer>
      <v-snackbar
        v-model="snackbar.show"
        :top="snackbar.top"
        :color="snackbar.color"
      >
        <v-row class="px-4 align-center">
          {{ snackbar.message }}
          <v-spacer></v-spacer>
          <v-btn dark text color="white" @click.native="closeSnackbar"
            >Close</v-btn
          ></v-row
        >
      </v-snackbar>
      <v-overlay :value="overlay && $vuetify.breakpoint.smAndDown"></v-overlay>
    </v-main>
    <transition name="scale-up">
      <v-btn
        :small="$vuetify.breakpoint.xsOnly"
        fab
        class="scroller brandred"
        v-if="dark"
        @click="scrollUp()"
      >
        <v-icon size="2rem" color="white">mdi-chevron-up</v-icon>
      </v-btn>
    </transition>
  </v-app>
</template>

<script>
import Toolbar from "./components/Toolbar";
import Footer from "./components/Footer";
import TopDisplay from "./components/TopDisplay";
import FootContact from "./components/FootContact";
import HomeDisplay from "./components/HomeDisplay";

export default {
  name: "App",

  components: {
    Toolbar,
    Footer,
    TopDisplay,
    FootContact,
    HomeDisplay,
  },

  data() {
    return {
      overlay: false,
      dark: false,
    };
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("snackbar/close");
    },
    checkOffset() {
      let offsetTop = document.querySelector(".router-body").offsetTop;
      if (window.pageYOffset >= offsetTop - 55) {
        this.dark = true;
      } else {
        this.dark = false;
      }
    },
    scrollUp() {
      window.scrollTo(0, 0);
    },
  },

  computed: {
    snackbar: function() {
      return this.$store.state.snackbar;
    },
  },

  mounted() {
    let target = document.querySelector("#toolbar");
    target.classList.remove("white");
    let $this = this;
    let timeout;
    window.addEventListener("scroll", () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        $this.checkOffset();
      }, 50);
    });
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
};
</script>
<style>
@import url("./my-modules/css/global.css");
</style>
