export default {
  namespaced: true,
  state: {
    show: false,
    message: "Alert!",
    top: true,
    color: "brand",
  },
  getters: {
    get: (state) => {
      state.show, state.message;
    },
  },
  mutations: {
    set: (state, payload) => {
      (state.show = payload.show),
        (state.message = payload.message),
        (state.top = payload.top || true),
        (state.color = payload.color || "brand");
    },
    close: (state) => (state.show = false),
  },
};
