const axios = require("axios");

const URL = "projects";

export default {
  namespaced: true,
  actions: {
    async fetchProjects(commit, type) {
      try {
        let res = await axios.get(URL + "?type=" + type);
        return res.data;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchProject(commit, id) {
      try {
        let res = await axios.get(URL + "/" + id);
        return res.data;
      } catch (err) {
        console.error(err);
      }
    },
    async uploadProject({ rootGetters }, project) {
      try {
        const config = {
          headers: { "x-auth-token": rootGetters["users/token"] },
          "content-type": "multipart/form-data",
        };
        await axios.post(URL, project, config);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
