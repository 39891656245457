<template>
  <div>
    <div style="font-family: 'Rubik', sans-serif">
      <div style="
                                      font-family: 'Rubik', sans-serif;
                                      line-height: 24px !important;
                                      font-size: 18px;
                                      padding: 50px 30px;
                                    " class="shadowed" id="resume">
        <h1 style="text-align: center; font-weight: normal">VICTOR ONWUKWE</h1>
        <div style="text-align: center">
          <div>
            <span>27 Okekemlane, Woji, Port Harcourt</span><span style="margin: 0 18px">|</span> <span>+234 813 213
              1972</span><span style="margin: 0 18px">|</span>
            <span>victoronwukwe25@gmail.com</span><span style="margin: 0 18px">|</span>
            <span>Website: victoronwukwe.com</span>
          </div>
        </div>
        <div>
          <h2 style="
                                          text-align: center;
                                          border-top: 1px solid black;
                                          border-bottom: 1px solid black;
                                          font-weight: normal;
                                          margin: 16px 0;
                                          padding: 0.7em 0;
                                        " class="py-0">
            WORK EXPERIENCE
          </h2>
          <div v-for="organization in $store.getters['jobs/organizations']" :key="organization.name"
            style="margin-top: 24px">
            <div>
              <div style="display: inline-block; width: 50%">
                <div style="font-weight: bold">{{ organization.name }}</div>
              </div>
              <div style="
                                              display: inline-block;
                                              width: 50%;
                                              text-align: right;
                                              font-weight: bold;
                                            ">
                {{ dayjs(organization.startDate).format("MMMM YYYY") + " - " + (organization.endDate ?
                  dayjs(organization.endDate).format("MMMM YYYY") : "Present") }}
              </div>
            </div>
            <div style="font-style: italic; margin-bottom: 12px; font-weight: 200">
              {{ organization.position }}
            </div>

            <div v-for="(achievement, index) in organization.notableMentions" :key="index" style="white-space: nowrap">
              <div style="
                                              display: inline-block;
                                              vertical-align: top;
                                              margin-right: 15px;
                                              margin-left: 30px;
                                            ">
                &#8680;
              </div>
              <div style="
                                              display: inline-block;
                                              white-space: normal;
                                              padding-right: 30px;
                                            ">
                {{ achievement }}
              </div>
            </div>
          </div>
        </div>
        <div style="margin: 32px 0">
          <h2 style="
                                          text-align: center;
                                          border-top: 1px solid black;
                                          border-bottom: 1px solid black;
                                          font-weight: normal;
                                          margin: 16px 0;
                                          padding: 0.7em 0;
                                        " class="py-0">
            PROJECTS
          </h2>
          <div v-for="project in $store.getters['jobs/projects']" :key="project.id" style="margin-top: 24px">
            <div>
              <div style="display: inline-block; width: 50%">
                <div style="font-weight: bold">{{ project.name }}</div>
              </div>
              <div style="
                                              display: inline-block;
                                              width: 50%;
                                              text-align: right;
                                              font-weight: bold;
                                            ">
                {{ creationDate(project.creationDate) }}
              </div>
            </div>
            <div style="font-style: italic; font-weight: 200; margin-bottom: 10px">
              {{ project.role }}
              {{
                project.teamSize > 1 ? `in a team of ${project.teamSize}` : ""
              }}
            </div>
            <div style="margin-bottom: 14px">
              <span v-for="tag in project.tags" :key="tag" style="
                                              background-color: rgba(0, 0, 0, 0.07);
                                              margin-right: 5px;
                                              padding: 3px 5px;
                                              border-radius: 3px;
                                              font-size: 14px;
                                            ">{{ tag }}</span>
            </div>
            <div v-for="(mention, index) in project.notableMentions" :key="index" style="white-space: nowrap">
              <div style="
                                              display: inline-block;
                                              vertical-align: top;
                                              margin-right: 15px;
                                              margin-left: 30px;
                                            ">
                &#8680;
              </div>
              <div style="
                                              display: inline-block;
                                              white-space: normal;
                                              padding-right: 30px;
                                            ">
                {{ mention }}
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 style="
                                          text-align: center;
                                          border-top: 1px solid black;
                                          border-bottom: 1px solid black;
                                          font-weight: normal;
                                          margin: 16px 0;
                                          padding: 0.7em 0;
                                        " class="py-0">
            EDUCATION
          </h2>
          <div>
            <div v-for="school in schools" :key="school.name" style="margin-top: 24px">
              <div style="display: inline-block; width: 50%">
                <div style="font-weight: bold">
                  {{ school.name }}
                </div>
              </div>
              <div style="
                                              display: inline-block;
                                              width: 50%;
                                              text-align: right;
                                              font-weight: bold;
                                            ">
                {{ school.startDate + " - " + school.endDate }}
              </div>

              <div style="
                                              font-style: italic;
                                              margin-bottom: 12px;
                                              font-weight: 200;
                                            ">
                {{ school.certification }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="authenticated" style="
                                      margin-top: 2em;
                                      font-family: 'Rubik', sans-serif;
                                      line-height: 24px !important;
                                      font-size: 18px;
                                      padding: 50px 30px;
                                    " class="shadowed" id="cover-letter">
        <h1 style="text-align: center; font-weight: normal">VICTOR ONWUKWE</h1>
        <div style="text-align: center">
          <div>
            <span>27 Okekemlane, Woji, Port Harcourt</span><span style="margin: 0 18px">|</span> <span>+234 813 213
              1972</span><span style="margin: 0 18px">|</span>
            <span>victoronwukwe25@gmail.com</span><span style="margin: 0 18px">|</span>
            <span>Website: victoronwukwe.com</span>
          </div>
        </div>
        <hr style="margin: 16px 0" />
        <div style="margin-top: 80px">
          <div v-if="receiver.name" style="margin-top: 0 !important">
            {{ receiver.name }}
          </div>
          <div style="margin-top: 10px">
            The
            {{ receiver.position || "......receiver position....." }},
          </div>
          <div style="margin-top: 10px">
            {{ receiver.companyName || "...company name..."
            }}{{ receiver.companyAddress ? "," : "." }}
          </div>
          <div v-if="receiver.companyAddress" style="margin-top: 10px">
            {{ receiver.companyAddress }}.
          </div>
          <p style="font-size: 18px; margin-top: 56px">
            Dear
            {{
              receiver.letterName ||
              receiver.name ||
              receiver.position ||
              "............"
            }},
          </p>

          <div v-if="customCoverLetter" style="white-space: normal">
            {{ customCoverLetter }}
          </div>
          <div v-else contenteditable="true">
            <p>
              I was excited to see to see your posting through
              {{ receiver.referrer || ".........." }} for the position of
              {{ receiver.availablePosition || ".........." }}. As a dedicated
              and dynamic full-stack developer with over two years of experience
              developing web applications, I believe I would be a valuable asset
              to the team at {{ receiver.companyName || ".........." }}.
            </p>
            <p>
              <span v-if="receiver.jobRequirements">Your job listing mentions a need for someone who is
                {{ receiver.jobRequirements || ".........." }}. I have extensive
                experience in all of these areas. </span>During my three-month internship with Afrilearn, I adapted to the
              project requirements by tapping into my Vuejs experience to learn
              Reactjs within my first week in the organization and went on to
              develop the platform's content management system and blog using
              Reactjs/Redux, while maintaining the speed and effectiveness
              required to launch the platform a month later. I've gone on to
              work on several other projects, garnering extensive experience in
              Nodejs, Reactjs, HTML, and CSS.
            </p>
            <p>
              I am confident that my experience, exceptional attention to
              detail, adaptability, and dedication, will make me an immediate
              asset at {{ receiver.companyName }}, and allow me to contribute to
              the team's success.
            </p>
            <p>
              I look forward to discussing the
              {{ receiver.availableposition }} position with you in more detail.
              I'm available to talk at your convenience. I'll be in touch to
              follow up and make sure you've received my application.
            </p>
            <p>Thank you so much for your time and consideration.</p>
            <p>Your's sincerely,</p>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/victor-onwukwe.appspot.com/o/signature.png?alt=media&token=1813749c-acc5-407f-8395-4ccf5ee17bc1"
              style="max-width: 40px" />
            <p>Victor onwukwe</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="authenticated">
      <div class="mt-12" style="border: 1px dotted black; display: inline-block; padding: 10px">
        <h4 class="mb-4">Select Portfolio image</h4>
        <input type="file" @change="setImage" /><button :disabled="!image" @click="uploadImage"
          class="upload-image-btn mt-3 mt-sm-0">
          {{ uploadingImage ? "Uploading..." : "Upload" }}
        </button>
      </div>

      <v-form class="mt-12" v-model="formValid">
        <h4>Email Details</h4>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field v-model="emailSubject" outlined label="Email subject"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="mt-n5 mt-sm-0">
            <v-text-field v-model="receiver.email" outlined label="Receiver's email"></v-text-field>
          </v-col>
        </v-row>
        <div v-if="sendCoverLetter">
          <h4>Receiver's Details</h4>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="receiver.name" outlined placeholder="Receiver's Name"
                label="Receiver's Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="mt-n5 mt-sm-0">
              <v-text-field v-model="receiver.position" outlined label="*Receiver's Position*"
                :rules="[rules.required(`Receiver's position`)]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="mt-n5">
              <v-text-field v-model="receiver.letterName" outlined label="Receiver's Letter name"
                placeholder="Name to display on letter, eg Dear 'Sir/Ma'"></v-text-field>
            </v-col>
          </v-row>

          <h4>Company's Details</h4>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="receiver.companyName" outlined label="*Company's Name*"
                :rules="[rules.required(`Company's name`)]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="mt-n5 mt-sm-0">
              <v-text-field v-model="receiver.companyAddress" outlined label="Company's Address"></v-text-field>
            </v-col>
          </v-row>
          <h4>Job Details</h4>
          <v-row>
            <v-col cols="12" sm="6" class="d-flex align-center">
              <input v-model="posPrefix" type="text" style="
                                              width: 40px;
                                              border: 1px solid grey !important;
                                              outline: none;
                                            " class="mr-2 px-1" />
              <v-text-field class="flex-grow" v-model="receiver.availablePosition" outlined label="*Available Position*"
                placeholder="Prefix: 'in need of'" :rules="[rules.required(`Available position`)]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="mt-n5 mt-sm-0">
              <v-text-field v-model="receiver.referrer" outlined placeholder="How did you find the job. Prefix: 'through'"
                label="*Referrer*" :rules="[rules.required(`Referrer`)]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-textarea v-model="receiver.jobRequirements" outlined rows="6" label="Job Requirements"
                placeholder="Prefix: for someone who is"></v-textarea>
            </v-col>
          </v-row>

          <h4>Custom Letter</h4>
          <v-textarea v-model="customCoverLetter" outlined rows="8"></v-textarea>
        </div>

        <v-checkbox label="Resume" v-model="sendResume"></v-checkbox>
        <v-checkbox label="Cover Letter" class="mt-n4" v-model="sendCoverLetter"></v-checkbox>
      </v-form>

      <m-button class="rufina button mr-3" outlined :disabled="
        (!formValid && sendCoverLetter) || (!sendCoverLetter && !sendResume)
      " tile :loading="sending" :animationPosition="'left'" :color="'var(--brand)'" @click="send()">
        <div class="rufina">SEND</div>
      </m-button>
      <m-button class="rufina button" outlined tile :disabled="
        (!formValid && sendCoverLetter) || (!sendCoverLetter && !sendResume)
      " :loading="downloading" :animationPosition="'left'" :color="'var(--brand)'" @click="download()">
        <div class="rufina">DOWNLOAD</div>
      </m-button>
    </div>
    <m-button v-else class="rufina button mt-6" outlined tile :loading="downloading" :animationPosition="'left'"
      :color="'var(--brand)'" @click="download(true)">
      <div class="rufina">DOWNLOAD</div>
    </m-button>
  </div>
</template>
<script>
// import ProgressBar from "../components/ProgressBar";
import Button from "../components/Button";
import { convertDate } from "../assets/js/globalFunctions.js";
import Rules from "../methods/Rules";
import dayjs from "dayjs";
// import html2canvas from "html2canvas";
export default {
  components: {
    // ProgressBar,
    "m-button": Button,
  },
  data() {
    return {
      skills: [
        { name: "HTML/CSS", value: "90" },
        { name: "Vuejs", value: "88" },
        { name: "Reactjs", value: "82" },
        { name: "Nodejs", value: "85" },
      ],
      schools: [
        {
          name: "KAAF University College, Ghana",
          certification: "Bachelor of Science, Mechanical Engineering",
          result: "Second Class, Upper Division",
          startDate: "2013",
          endDate: "2017",
        },
        // {
        //   name: "National Comprehensive Secondary School",
        //   certification: "WASSCE",
        //   startDate: "2012",
        //   endDate: "2013",
        // },
        // {
        //   name: "De-World International Secondary School",
        //   startDate: "2007",
        //   endDate: "2012",
        // },
      ],
      organizations: [
        {
          name: "Afrilearn",
          position: "FullStack Developer",
          description:
            "I was part of a team that developed the frontend and backend of the Content Management System and blog using Reactjs, Nodejs, and MongoDB over a three month period",
          startDate: "February 2021",
          endDate: "April 2021",
          achievements: [
            "Worked across the frontend and backend of the Content Management System implementing the creation and edition of Lessons (video and classnotes), quizzes, classes, subjects, terms.",
            "Enabled manual payment activation on the content management system.",
            "Implemented creation of articles, and fetching of articles across the frontend and backend of the blog.",
            "Developed the parent dashboard, and child performance review page on the main (end user) platform.",
          ],
        },
        {
          name: "Classy Groups",
          position: "Sales Executive",
          description:
            "I market roofing sheets. I'm responsible for reaching out to potential clients and directing them to the company",
          startDate: "March 2020",
          endDate: "February 2021",
          achievements: [
            "Marketed the company's product on various online platforms like Facebook, Twitter and Nairaland.",
            "Designed clients' roofs with accurate dimensions and calculations using Autodesk inventor.",
            "Made over two million naira in sales of company accessories.",
          ],
        },
      ],
      referees: [
        {
          name: "Engr. Nelson Amadi",
          occupation: "Deputy Director",
          organization: "Niger Delta Development Commission",
          phone: "08037111480",
        },
        {
          name: "H.R.H Ndubuisi Ogbuji",
          occupation: "Traditional Ruler",
          organization: "Isi Obehie Autonomous Community",
          phone: "08038964722",
        },
      ],
      receiver: {
        name: "",
        position: "Hiring Manager",
        companyName: "",
        companyAddress: "",
        availablePosition: "",
        referrer: "",
        email: "",
        letterName: "",
        jobRequirements: "",
      },
      sendDate: convertDate(new Date(), false, ["d", "m", "y"], true),
      projects: [],
      rules: Rules,
      formValid: false,
      posPrefix: "a",
      emailSubject: "",
      sendCoverLetter: true,
      sendResume: true,
      sending: false,
      downloading: false,
      uploadingImage: false,
      image: null,
      customCoverLetter: "",
      dayjs
    };
  },
  computed: {
    authenticated() {
      return this.$store.getters["users/authenticated"];
    },
  },
  methods: {
    async send() {
      if (!this.$store.getters["jobs/projects"].length)
        return alert("No projects available");
      if (!this.emailValid()) return alert("Invalid email");
      this.sending = true;
      const both = this.sendResume && this.sendCoverLetter;
      let resume = new XMLSerializer().serializeToString(
        document.getElementById("resume")
      );
      let coverLetter = new XMLSerializer().serializeToString(
        document.getElementById("cover-letter")
      );
      const textMessage = `Hello. Good day. This is Victor Onwukwe${this.receiver.referrer
        ? ". I came across your advert through " + this.receiver.referrer
        : ""
        }. Attached to this document ${both ? "are pdf copies of" : "is a pdf copy of"
        } my ${this.sendResume ? "curriculum vitae" : ""}${both ? ", and" : ""} ${this.sendCoverLetter ? "cover letter" : ""
        }. I hope to hear from you soon. Have a nice day.`;

      let message = `<p>Hello. Good day. This is Victor Onwukwe${this.receiver.referrer
        ? ". I came across your advert through " + this.receiver.referrer
        : ""
        }. Below is a html prepared version of my ${both
          ? "curriculum vitae and cover letter"
          : this.sendResume
            ? "curriculum vitae"
            : "cover letter"
        }. There's a chance ${both ? "they" : "it"
        } may not display properly on your email provider, so I attached a pdf of ${both ? "them" : "it"
        } too</p>`;

      message =
        message +
        `${this.sendResume
          ? '<h2 style="margin-top: 30px">CURRICULUM VITAE</h2>' + resume
          : ""
        }` +
        `${this.sendCoverLetter
          ? '<h2 style="margin-top:40px">COVER LETTER</h2>' + coverLetter
          : ""
        }`;

      message =
        `<!DOCTYPE html\\><html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title></head><body>` +
        message +
        `</body>
</html>`;
      resume =
        `<!DOCTYPE html\\><html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title></head><body">` +
        resume +
        `</body>
</html>`;
      coverLetter =
        `<!DOCTYPE html\\><html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title></head><body>` +
        coverLetter +
        `</body>
</html>`;

      if (!this.sendResume) {
        resume = false;
      }
      if (!this.sendCoverLetter) {
        coverLetter = false;
      }
      await this.$store.dispatch("jobs/sendResume", {
        htmlMessage: message,
        resume,
        coverLetter,
        receiver: this.receiver,
        textMessage,
        emailSubject:
          this.emailSubject ||
          `${this.sendCoverLetter
            ? `Application for ${this.receiver.availablePosition} at ${this.receiver.companyName}`
            : "CV submission"
          }`,
      });
      this.sending = false;
    },
    async download(isGuest) {
      if (!this.$store.getters["jobs/projects"].length)
        return alert("No projects available");
      this.downloading = true;
      let resume = new XMLSerializer().serializeToString(
        document.getElementById("resume")
      );
      let coverLetter = isGuest
        ? ""
        : new XMLSerializer().serializeToString(
          document.getElementById("cover-letter")
        );
      resume =
        `<!DOCTYPE html\\><html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"><title>Document</title></head><body">` +
        resume +
        `</body>
</html>`;
      coverLetter =
        `<!DOCTYPE html\\><html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title></head><body>` +
        coverLetter +
        `</body>
</html>`;

      if (!this.sendResume) {
        resume = false;
      }
      if (isGuest || !this.sendCoverLetter) {
        coverLetter = false;
      }
      await this.$store.dispatch("jobs/downloadResume", {
        resume,
        coverLetter,
        companyName: isGuest ? "" : this.receiver.companyName,
      });
      this.downloading = false;
    },
    creationDate(date) {
      return convertDate(date, false, ["m", "y"]);
    },
    emailValid() {
      const v = this.receiver.email;
      return (
        !!v &&
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v.trim()
        )
      );
    },
    setImage(e) {
      this.image = e.target.files[0];
    },
    async uploadImage() {
      this.uploadingImage = true;
      const formData = new FormData();
      formData.set("type", "portfolio");
      formData.set("image", this.image);

      await this.$store.dispatch("users/uploadImage", formData);
      this.uploadingImage = false;
      this.$store.dispatch("users/fetchImage", "portfolio");
    },
  },
  async mounted() {
    this.$store.dispatch("jobs/fetchProjects", "all");
    this.$store.dispatch("jobs/fetchOrganizations", "all");
    this.$store.dispatch("users/fetchImage", "portfolio");
  },
};
</script>
<style scoped>
* {
  font-family: "Rubik", sans-serif !important;
}

#resume>div {
  border: none !important;
}

.resume-flex {
  display: flex;
}

.resume-flex>*:first-child {
  max-width: 350px;
  min-width: 12.5em;
  width: 100%;
}

.resume-flex>*:nth-child(2) {
  flex-grow: 1;
  padding: 30px;
}

.resume-title {
  background: linear-gradient(var(--resume-primary), var(--resume-primary));
  background-position: 0 100%;
  background-size: 100% 3px;
  background-repeat: no-repeat;
  display: inline-block;
}

.side-menu>* {
  padding: 30px;
}

.side-menu * {
  color: white;
}

.work-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.referee-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
  gap: 24px;
}

.referee-container>div {
  width: auto !important;
}

.upload-image-btn {
  background-color: var(--brand);
  color: white;
  padding: 5px 10px;
}

.upload-image-btn:disabled {
  background-color: lightgray;
  color: rgba(0, 0, 0, 0.6);
}

.left-padding {
  padding-left: 16px !important;
}

.shadowed {
  box-shadow: 0 3px 0.3125em 3px rgba(0, 0, 0, 0.1);
}
</style>