const axios = require("axios");

const URL = "organizations";

export default {
  namespaced: true,
  actions: {
    async fetchOrganizations(commit, type) {
      try {
        let res = await axios.get(URL + "?type=" + type);
        return res.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async fetchOrganization(commit, id) {
      try {
        let res = await axios.get(URL + "/" + id);
        return res.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async uploadOrganization({ rootGetters }, organization) {
      try {
        console.log(organization.getAll("lowImages"));
        console.log(organization.get("name"));
        const config = {
          headers: { "x-auth-token": rootGetters["users/token"] },
          "content-type": "multipart/form-data",
        };
        await axios.post(URL, organization, config);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
  },
};
