<template>
  <v-app-bar
    fixed
    shrink-on-scroll
    height="100px"
    class="px-0 de-elevate"
    :class="{ 'elevation-4 white': dark }"
    id="toolbar"
  >
    <div
      style="display:flex;width:100vw;height:100%;align-items:center;justify-content:center;margin-left:-16px;margin-right:-16px"
      class="dynamic-padding"
    >
      <div style="max-width:1200px;display:flex;align-items:center;width:100%">
        <router-link to="/" style="background-image:none" class="my-auto">
          <div
            class="font-weight-bold brand--text shadow-text"
            style="line-height:1;text-align:center"
          >
            VICTOR'S
          </div>
          <div
            class="font-weight-bold rufina shadow-text"
            style="line-height:0.6;text-align:center"
            :style="{
              color: dark ? 'rgba(0,0,0,0.87)' : 'rgba(255,255,255,0.8)',
            }"
          >
            DESIGNS
          </div>
        </router-link>
        <v-spacer></v-spacer>
        <div v-show="$vuetify.breakpoint.mdAndUp" class="ml-12">
          <router-link
            :to="'/'"
            class="mr-lg-12 mr-md-9 nav"
            :style="
              dark
                ? 'color: rgba(0,0,0,0.87);'
                : 'color: rgba(255,255,255,0.9);'
            "
            >Home</router-link
          >
          <router-link
            :to="'/about'"
            class="mr-lg-12 mr-md-9 nav"
            :style="{
              color: dark ? 'rgba(0,0,0,0.87)' : 'rgba(255,255,255,0.9)',
            }"
            >About</router-link
          >
          <router-link
            :to="'/services'"
            class="mr-lg-12 mr-md-9 nav"
            :style="{
              color: dark ? 'rgba(0,0,0,0.87)' : 'rgba(255,255,255,0.9)',
            }"
            >My Services</router-link
          >
          <router-link
            :to="'/portfolio'"
            class="mr-lg-12 mr-md-9 nav"
            :style="{
              color: dark ? 'rgba(0,0,0,0.87)' : 'rgba(255,255,255,0.9)',
            }"
            >Portfolio</router-link
          >
          <router-link
            :to="'/contact'"
            class="nav"
            :style="{
              color: dark ? 'rgba(0,0,0,0.87)' : 'rgba(255,255,255,0.9)',
            }"
            >Contact</router-link
          >
          <router-link
            :to="'/job-profile'"
            class="ml-lg-12 ml-md-9 nav"
            :style="{
              color: dark ? 'rgba(0,0,0,0.87)' : 'rgba(255,255,255,0.9)',
            }"
            >Job Profile</router-link
          >
          <router-link
            to="/consult"
            style="background-image:none"
            class="ml-md-9 ml-lg-12"
          >
            <m-button
              v-show="$vuetify.breakpoint.mdAndUp"
              :dark="true"
              animationPosition="top"
              :outlined="!dark"
              :color="!dark ? 'white' : 'var(--brand)'"
              :classes="['px-6 py-3']"
              ><div style="font-size:16px">
                {{
                  $vuetify.breakpoint.md
                    ? "Free Consultant"
                    : "Get Free Consultant"
                }}
              </div></m-button
            >
          </router-link>
        </div>
        <v-app-bar-nav-icon
          v-show="$vuetify.breakpoint.smAndDown"
          :color="dark ? 'brand' : 'rgba(255,255,255,0.9)'"
          @click="toggleDropDown()"
          x-large
          style="margin-right:-14px"
        ></v-app-bar-nav-icon>
      </div>
    </div>
    <div
      v-show="$vuetify.breakpoint.smAndDown"
      style="position:absolute;top: 3.8em;width:100%;left:0;display:flex"
      class="menu"
    >
      <div
        style="width:90%; border-radius:4px; background-color: rgba(255,255,255,0.98)"
        class="mx-auto elevation-2 px-4 pb-1 m-nav-container"
        @click="toggleDropDown()"
      >
        <router-link to="/" exact-active-class="brand--text" class="m-nav py-4">Home</router-link>
        <router-link to="/about" exact-active-class="brand--text" class="m-nav py-4">About</router-link>
        <router-link to="/services" exact-active-class="brand--text" class="m-nav py-4"
          >My Services</router-link
        >
        <router-link to="/portfolio" exact-active-class="brand--text" class="m-nav py-4"
          >Portfolio</router-link
        >
        <router-link to="/contact" exact-active-class="brand--text" class="m-nav py-4"
          >Contact</router-link
        >
        <router-link v-if="$store.getters['users/authenticated']" to="/job-profile" exact-active-class="brand--text" class="m-nav py-4"
          >Job Profile</router-link
        >
        <div style="display:flex" class="mt-6">
          <router-link
            to="/consult"
            style="background-image:none"
            class="ml-auto"
          >
            <m-button :dark="true" :bodyClasses="[]" :classes="['px-6 py-3']"
              ><div style="font-size:16px">Get Free Consultant</div></m-button
            >
          </router-link>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import Button from "../components/Button";
export default {
  name: "Toolbar",
  components: {
    "m-button": Button,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pastThreshold: false,
      dropDown: false,
      items: [{ name: "home" }, { name: "Contact" }, { name: "Services" }],
    };
  },
  methods: {
    toggleDropDown() {
      this.dropDown = !this.dropDown;
      let menu = document.querySelector(".menu");
      this.dropDown
        ? menu.classList.add("show")
        : menu.classList.remove("show");
      this.$emit("toggle", this.dropDown);
    },
  },
};
</script>

<style scoped>
.nav {
  font-size: 15px;
  font-weight: 400;
}
.m-nav {
  font-size: 15px;
  color: var(--text-primary);
  font-weight: 500;
  display: block;
  background-image: none;
  transition: color 0.2s ease;
}
.m-nav:hover{
  color: var(--brand);
}
.menu {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s ease;
}
.menu.show {
  transform: scaleY(1);
}

</style>
