<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="icon">
    <g>
      <path
        d="M 16.28125 0.03125 C 16.153719 0.05625 16.02075 0.07875 15.90625 0.15625 C 15.44825 0.46525 15.34725 1.1045 15.65625 1.5625 L 17.8125 4.78125 C 14.478784 6.5458002 11.996829 9.4814955 11.1875 13 L 38.8125 13 C 38.002559 9.4814955 35.521308 6.5458002 32.1875 4.78125 L 34.34375 1.5625 C 34.65175 1.1045 34.55175 0.46525 34.09375 0.15625 C 33.63475 -0.15275 32.9975 -0.0205 32.6875 0.4375 L 30.3125 3.9375 C 28.66298 3.3377371 26.873338 3 25 3 C 23.126662 3 21.336998 3.3377371 19.6875 3.9375 L 17.3125 0.4375 C 17.0815 0.094 16.663844 -0.04375 16.28125 0.03125 z M 19.5 8 C 20.327 8 21 8.672 21 9.5 C 21 10.332 20.327 11 19.5 11 C 18.667 11 18 10.332 18 9.5 C 18 8.672 18.667 8 19.5 8 z M 30.5 8 C 31.333 8 32 8.672 32 9.5 C 32 10.332 31.333 11 30.5 11 C 29.673 11 29 10.332 29 9.5 C 29 8.672 29.673 8 30.5 8 z M 8 15 C 6.343 15 5 16.343 5 18 L 5 32 C 5 33.657 6.343 35 8 35 C 8.353 35 8.686 34.9245 9 34.8125 L 9 15.1875 C 8.686 15.0755 8.353 15 8 15 z M 11 15 L 11 37 C 11 38.654 12.346 40 14 40 L 36 40 C 37.654 40 39 38.654 39 37 L 39 15 L 11 15 z M 42 15 C 41.647 15 41.314 15.0755 41 15.1875 L 41 34.8125 C 41.314 34.9235 41.647 35 42 35 C 43.657 35 45 33.657 45 32 L 45 18 C 45 16.343 43.657 15 42 15 z M 15 42 L 15 46 C 15 48.206 16.794 50 19 50 C 21.206 50 23 48.206 23 46 L 23 42 L 15 42 z M 27 42 L 27 46 C 27 48.206 28.794 50 31 50 C 33.206 50 35 48.206 35 46 L 35 42 L 27 42 z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "android",
};
</script>
<style scoped>
.icon {
  fill: currentColor;
}
</style>
