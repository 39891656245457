export function convertDate (
  date,
  numeric = false,
  show = ['d', 'm', 'y'],
  isLetter = false
) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const getLetterDay = day => {
    if (day % 10 === 1 && day !== 11) return day + 'st'
    else if (day % 10 === 2 && day !== 12) return day + 'nd'
    else if (day % 10 === 3 && day !== 13) return day + 'rd'
    else return day + 'th'
  }
  function includes (arr, val) {
    return arr.includes(val)
  }
  function pad (s) {
    return s < 10 ? '0' + s : s
  }
  var d = new Date(date)
  let day = d.getDate()
  let month = d.getMonth()
  let year = d.getFullYear()
  if (isNaN(day) || isNaN(month) || isNaN(year)) return 'Invalid date'
  return !numeric
    ? isLetter
      ? `${getLetterDay(day)} ${months[month]}, ${year}`
      : `${
          includes(show, 'm')
            ? months[month] +
              ' ' +
              `${includes(show, 'd') ? pad(day) + ', ' : ''}`
            : ''
        }${includes(show, 'y') ? year : ''}`
    : `${includes(show, 'd') && includes(show, 'm') ? pad(day) + '-' : ''}${
        includes(show, 'm') ? month + 1 + '-' : ''
      }
      ${includes(show, 'y') ? year : ''}`
}
