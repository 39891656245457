<template>
  <div style="animation: slide-up 0.5s ease">
    <div class="description">
      <div class="img">
        <div style="position: relative; width: 100%">
          <v-img
            :src="
              $store.getters['users/aboutImage'].url ||
              require('../assets/homeImage/aboutImage.jpg')
            "
            contain
            max-width="500px"
            width="100%"
          ></v-img>
          <div
            v-if="authenticated"
            style="
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              max-width: 500px;
              background-color: rgba(255, 255, 255, 0.6);
            "
          >
            <input
              style="height: 100%; width: 70%"
              type="file"
              accept="image/*"
              @input="setImage"
            />
            <button
              @click="uploadAboutImage"
              :style="`background-color: ${
                !newImage ? 'lightgray' : 'var(--brand)'
              };color: white;padding: 10px;width:30%`"
              :disabled="!newImage"
            >
              Upload{{ uploadingImage ? "ing..." : "" }}
            </button>
          </div>
        </div>
      </div>
      <div class="text">
        <div style="max-width: ">
          <h1 class="h1">Lend Me your Lens, Let Me Look Through It...</h1>
          <div class="mt-6">
            <p
              class="darkblue--text"
              style="line-height: 2em; font-size: 0.9em; display: inline"
            >
              I am Victor Onwukwe, a B.Sc holder in Mechanical Engineering and a
              Full stack developer. I have always loved aesthetics and
              structure; two features I have integrated with my passion for
              creation which overtime, has been channeled to various paths: from
              art, to machine designs, to writing; having as much fun (in a
              different way) in each as the others. However, it is in the Tech
              World that I have found the most intrigue, especially when looking
              back at how far the human race has come. It allows room for vast
              imagination, and is the bedrock for modern day convenient living.
              I consider my services only a freckle of effort amongst others in
              our collective goal to advance humanity, and I consider you an
              originator of ideas - a catalyst, to achieving that goal.
            </p>
          </div>
          <!-- <div
            style="padding-left: 1em; border-left: 2px solid var(--brandred); margin: 2em 0;border-left-height: 5px"
          >
            <h3 style="line-height:1.2">
              I'm just a boy, sitting in front of a computer screen,
              typing&nbsp;&nbsp; "01001001 00100000 01001100 01101111 01110110
              01100101 00100000 01011001 01101111 01110101"
            </h3>
          </div>
          <p
            class="mt-6 darkblue--text"
            style="line-height:2em; font-size: 0.9em"
          >
            
          </p> -->
        </div>
      </div>
    </div>
    <h1 style="margin-top: 3em">Awards & Badges</h1>
    <div style="margin-top: 1em" class="d-flex badges flex-wrap">
      <div
        v-for="badge in badges"
        :key="badge.image"
        style="max-width: 300px"
        class="d-flex flex-column align-center"
      >
        <v-img
          :src="require('../assets/badges/' + badge.image)"
        ></v-img>
        <div class="mt-2 font-weight-bold">{{ badge.desc }}</div>
      </div>
    </div>

    <!-- <h1 style="margin-top:2em">Some Positive FeedBacks That Encourage Me!</h1>
    <div class="reviews mt-12">
      <splide :options="options" ref="splide">
        <splide-slide v-for="n in 8" :key="n">
          <div class="py-8 px-8 review" style="background-color: rgba(0,0,0,0)">
            <v-list-item class="px-0">
              <v-list-item-avatar class="mr-4" size="3.5em">
                <v-img
                  :src="require('../assets/homeImage/default.jpg')"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="brandred--text rufina">
                  Nelson Amadi
                </v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-light mt-2 darkblue--text"
                  style="font-size:0.8em"
                  >Civil Engineer</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-card-text
              class="ptd px-0 lighterblue--text font-wei"
              style="line-height: 2"
            >
              I really liked Victor's service. He was very professional all
              through, and paid attention to little but important details. He
              readily mirrored what we I had in mind, and even offered better
              ways to optimize the design of the website. I would recommend him
              to the next person.
            </v-card-text>
          </div>
        </splide-slide>
      </splide>
      <div class="d-flex justify-center mt-6">
        <div>
          <v-icon
            style="border:1px solid var(--brand);border-radius:50%"
            size="2.5em"
            @click="slide('-')"
            color="brand"
            >mdi-chevron-left</v-icon
          >
          <v-icon
            style="border:1px solid var(--brand);border-radius:50%"
            size="2.5em"
            v-if="authenticated"
            color="brand"
            class="ml-4"
            >mdi-plus</v-icon
          >
          <v-icon
            style="border:1px solid var(--brand);border-radius:50%"
            size="2.5em"
            @click="slide('+')"
            color="brand"
            class="ml-4"
            >mdi-chevron-right</v-icon
          >
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import "@splidejs/splide/dist/css/themes/splide-default.min.css";
// import { Splide, SplideSlide } from "@splidejs/vue-splide";
import FormData from "form-data";

export default {
  components: {
    // Splide,
    // SplideSlide,
  },
  data() {
    return {
      options: {
        type: "loop",
        perPage: 3,
        gap: "2em",
        perMove: 1,
        arrows: false,
        focus: "center",
        breakpoints: {
          900: {
            perPage: 2,
          },
          600: {
            perPage: 1.5,
          },
          400: {
            perPage: 1,
          },
        },
      },
      newImage: null,
      uploadingImage: false,
      badges: [
        {
          desc: "Upwork Top Rated Badge",
          image: "upwork-top-rated.png",
        },
        {
          desc: "IBM Explorer Badge",
          image: "IBM - explorer.png",
        },
      ],
    };
  },
  methods: {
    slide(dir) {
      this.$refs.splide.go(dir);
    },
    setImage(e) {
      this.newImage = e.target.files[0];
    },
    async uploadAboutImage() {
      this.uploadingImage = true;
      let formData = new FormData();
      formData.set("type", "about");
      formData.set("image", this.newImage);
      await this.$store.dispatch("users/uploadImage", formData);
      this.uploadingImage = false;
      this.$store.dispatch("users/fetchImage", "about");
    },
  },
  computed: {
    authenticated() {
      return this.$store.getters["users/authenticated"];
    },
  },
  mounted() {
    this.$store.dispatch("users/fetchImage", "about");
  },
};
</script>
<style scoped>
.description {
  display: grid;
  grid-gap: 1em;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}
.badges {
  display: flex;
  margin: 0 -1em;
}
.badges > * {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 1em;
  margin: 1em;
}
@media (min-width: 600px) {
  .description {
    grid-gap: 2em;
  }
}
@media (min-width: 960px) {
  .description {
    grid-gap: 3em;
  }
}
.review {
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 91%);
}
.img {
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: flex-start;
  position: relative;
}
.text {
  display: flex;
  justify-content: end;
  width: 100%;
}
.slide-fade-enter-active {
  transition: all 2s ease;
}
.slide-fade-leave-active {
  transition: all 2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
@media (max-width: 754px) {
  .img,
  .text {
    justify-content: center;
  }
}
</style>
