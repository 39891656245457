<template>
  <div
    style="
  animation: slide-up 0.5s ease;"
  >
    <div class="cover" v-if="!loading">
      <div class="slide">
        <splide :options="primaryOptions" ref="primary" class="elevation-3">
          <splide-slide
            v-for="(image, n) in project.images"
            :key="n"
            style="background-color: rgba(15,18,50,1)"
          >
            <v-img
              @click="displayImage(n)"
              :src="image.high"
              :lazy-src="image.low"
              alt="slide.alt"
              class="pointer"
              contain
              style="width:100%;height:100%;"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    width="3"
                    color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </splide-slide>
        </splide>

        <div style="position:relative">
          <splide
            :options="secondaryOptions"
            ref="secondary"
            style="margin-top:1rem;position:relative"
          >
            <splide-slide v-for="(image, n) in project.images" :key="n">
              <v-img
                :src="image.low"
                alt="slide.alt"
                width="100%"
                height="100%"
                class="elevation-2"
              >
                <template v-slot:placeholder>
                  <v-skeleton-loader
                    class=""
                    max-width="100%"
                    type="image"
                    style="border-radius:0"
                  ></v-skeleton-loader>
                </template>
              </v-img>
            </splide-slide>
          </splide>
          <v-icon
            @click="go('secondary', '-')"
            color="white"
            size="2em"
            class="arrow elevation-5"
            style="left:0.5em"
            v-if="!$vuetify.breakpoint.xs"
            >mdi-chevron-left</v-icon
          >
          <v-icon
            @click="go('secondary', '+')"
            color="white"
            size="2em"
            class="arrow elevation-5"
            style="right:0.5em"
            v-if="!$vuetify.breakpoint.xs"
            >mdi-chevron-right</v-icon
          >
        </div>
        <div
          class="mt-6 d-flex"
          :class="
            $vuetify.breakpoint.xs
              ? 'flex-column-reverse'
              : 'justify-space-between align-start'
          "
        >
          <div>
            <div class="mb-4" style="display:flex">
              <div class="std" style="min-width:80px;display:inline-block">
                Created:
              </div>
              <div>
                {{ dateString }}
              </div>
            </div>
            <div v-if="project.features" style="display:flex" class="mb-4">
              <div class="std" style="min-width:80px;display:inline-block">
                Features:
              </div>
              <div>
                {{ project.features }}
              </div>
            </div>
            <div class="std" style="display:flex">
              <div class="std" style="min-width:80px;display:inline-block">
                Tags:
              </div>
              <div class="tag-chips">
                <v-chip
                  v-for="(tag, i) in project.tags"
                  :key="i"
                  color="brand lighten-4"
                  class="mb-1"
                  >{{ tag }}</v-chip
                >
              </div>
            </div>
          </div>
          <div style="display:flex;margin-left:auto">
            <!-- <v-icon size="1.5em" color="brand" class="mx-3" v-if="authenticated"
              >mdi-pen</v-icon
            > -->
            <a
              :href="project.link"
              target="_blank"
              style="background-image:none"
              v-if="project.link"
            >
              <v-icon size="1.4em" color="brand" tag="a"
                >$vuetify.icons.external-link</v-icon
              >
            </a>
          </div>
        </div>
      </div>
      <div class="infos">
        <h1 class="ptd rubik text-capitalize rufina">{{ project.name }}</h1>
        <p class="mt-4" style="white-space: pre-wrap">{{ project.description }}</p>
      </div>
      <div style="clear:left"></div>
    </div>
    <div v-else style="width:100%">
      <div
        class="d-flex"
        :class="{
          'flex-column': screenWidth < 850 && !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          class="d-flex flex-column col-xs-12 col-md-6"
          style="overflow:hidden"
        >
          <v-skeleton-loader
            :type="$vuetify.breakpoint.mdAndUp ? 'image, image' : 'image'"
            width="100%"
            height="48.5%"
          ></v-skeleton-loader>
          <div class="d-flex px-0 mt-4" style="width:100%">
            <v-skeleton-loader
              v-for="n in 10"
              :key="n + 'a'"
              type="image"
              max-height="80px"
              min-width="120px"
              class="mr-4"
              :class="{ 'pl-0': n == 1 }"
            ></v-skeleton-loader>
          </div>
          <v-skeleton-loader
            type="text"
            width="100%"
            class="mt-4"
          ></v-skeleton-loader>
          <div class="d-flex flex-wrap mt-2" style="width:100%">
            <v-skeleton-loader
              v-for="n in 5"
              :key="n + 'b'"
              type="chip"
              class="mr-2 mt-2"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 d-flex flex-column">
          <v-skeleton-loader
            v-for="n in 1"
            :key="n + 'c'"
            type="article"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-for="n in 16"
            :key="n + 'd'"
            type="text"
            class="px-4 mb-1"
          ></v-skeleton-loader>
          <!-- <v-skeleton-loader
            v-for="n in 1"
            :key="n + 'e'"
            type="article"
          ></v-skeleton-loader> -->
          <!-- <v-skeleton-loader
            v-for="n in 4"
            :key="n + 'f'"
            type="text"
            class="px-4 mb-1"
          ></v-skeleton-loader> -->
        </div>
      </div>
      <div class="mt-0 mt-md-n12">
        <v-skeleton-loader
          v-for="n in 6"
          :key="n + 'f'"
          type="text"
          class="mb-1 px-7 px-md-3"
        ></v-skeleton-loader>
      </div>
    </div>
    <v-dialog
      v-model="showImage"
      :fullscreen="$vuetify.breakpoint.xsOnly || dialogFullscreen"
      v-if="project"
      scrollable
      max-width="950px"
      transition="dialog-transition"
    >
      <div class="black d-flex flex-column" style="position:relative">
        <div
          class="pa-4 white d-flex justify-space-between align-center"
          style="box-shadow: 0 0px 10px rgba(0,0,0,0.1);position:sticky;top:0;z-index:1;"
        >
          <div class="font-weight-bold text-capitalize" v-if="project.images">
            {{ dialogSlideName }}
          </div>
          <div>
            <v-icon
              v-show="$vuetify.breakpoint.smAndUp"
              class="dialog-icon mr-2"
              size="1.5em"
              @click="
                resizeScreen(() => {
                  dialogFullscreen = !dialogFullscreen;
                })
              "
              >{{
                dialogFullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen"
              }}</v-icon
            >
            <v-icon
              @click="go('dialogSlide', '-')"
              class="dialog-icon mr-2"
              :size="$vuetify.breakpoint.xs ? '1.2em' : '1.5em'"
              >mdi-chevron-left</v-icon
            >
            <v-icon
              @click="go('dialogSlide', '+')"
              class="dialog-icon mr-2"
              :size="$vuetify.breakpoint.xs ? '1.2em' : '1.5em'"
              >mdi-chevron-right</v-icon
            >
            <v-icon
              class="dialog-icon"
              :size="$vuetify.breakpoint.xs ? '1.2em' : '1.5em'"
              @click="showImage = false"
              >mdi-close</v-icon
            >
          </div>
        </div>
        <div class="d-flex flex-column justify-center grow">
          <splide :options="dialogOptions" ref="dialogSlide" class="my-auto">
            <splide-slide
              v-for="(image, n) in project.images"
              :key="n"
              style="background-color:rgba(15,18,50,1)"
            >
              <div
                v-if="!adjustingWindow"
                class="d-flex align-center"
                style="height:100%;position:relative"
              >
                <v-img
                  :src="image.high"
                  :lazy-src="image.low"
                  contain
                  max-width="100%"
                  height="100%"
                  class="mr-n1"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey"
                        width="3"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <div style="width:4px">i</div>
              </div>
            </splide-slide>
          </splide>
        </div>
        <div class="d-flex justify-center black" v-if="adjustingWindow">
          <v-progress-circular
            color="white"
            class="my-12"
            width="3"
            :value="20"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import "@splidejs/splide/dist/css/splide-core.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import Slide from "@splidejs/splide";

const main = {
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      primaryOptions: {
        type: "loop",
        perPage: 1,
        perMove: 1,
        pagination: false,
        arrows: false,
        lazyLoad: "nearby",
        cover: false,
        heightRatio: 0.4852631579,
      },
      dialogOptions: {
        type: "loop",
        perPage: 1,
        perMove: 1,
        pagination: true,
        arrows: false,
        autoWidth: false,
        lazyLoad: "nearby",
      },
      secondaryOptions: {
        type: "slide",
        rewind: true,
        gap: "1rem",
        pagination: false,
        fixedWidth: 110,
        fixedHeight: 70,
        cover: true,
        focus: "center",
        isNavigation: true,
        updateOnMove: true,
        breakpoints: {
          450: {
            fixedWidth: 80,
            fixedHeight: 50,
          },
        },
        lazyLoad: "nearby",
        arrows: false,
      },
      count: 0,
      project: {},
      loading: true,
      showImage: false,
      currentDialogIndex: 0,
      adjustingWindow: true,
      dialogLoaded: false,
      dialogSplide: {},
      dialogFullscreen: false,
    };
  },
  methods: {
    async fetchProject() {
      this.project = await this.$store.dispatch(
        "projects/fetchProject",
        this.$route.query.id
      );
      this.loading = false;
      setTimeout(() => {
        this.$refs.primary.sync(this.$refs.secondary.splide);
      }, 1000);
    },
    displayImage(index) {
      this.currentDialogIndex = index;
      this.showImage = true;
      const $this = this;
      if (!this.dialogLoaded)
        this.resizeScreen(() => {
          $this.go("dialogSlide", index);
          $this.adjustingWindow = false;
          $this.dialogLoaded = true;
        });
      else {
        setTimeout(() => {
          if (this.$refs["dialogSlide"].index !== index)
            $this.go("dialogSlide", index);
        }, 500);
      }
    },
    resizeScreen(cb) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
          cb();
        }, 200);
      }, 200);
    },
    go(ref, dir) {
      this.$refs[ref].go(dir);
    },
  },
  computed: {
    slides() {
      return [
        { src: "../assets/homeImage/Animals.jpg" },
        { src: "../assets/homeImage/Animals.jpg" },
        { src: "../assets/homeImage/Animals.jpg" },
        { src: "../assets/homeImage/Animals.jpg" },
        { src: "../assets/homeImage/Animals.jpg" },
        { src: "../assets/homeImage/Animals.jpg" },
        { src: "../assets/homeImage/Animals.jpg" },
      ];
    },
    authenticated() {
      return this.$store.getters["users/authenticated"];
    },
    dialogSlideName() {
      return this.$refs["dialogSlide"]
        ? this.project.images[this.$refs["dialogSlide"].index].name
        : this.project.images[this.currentDialogIndex].name;
    },
    dateString() {
      if (!this.project || !this.project.creationDate) return "null";
      let dateString = this.project.creationDate.slice(0, 10);
      let elems = dateString.split("-");
      let string = "";
      let day = elems[2];
      string += day;
      switch (day.charAt(1)) {
        case "1":
          string += day == "11" ? "th" : "st";
          break;
        case "2":
          string += day == "12" ? "th" : "nd";
          break;
        case "3":
          string += day == "13" ? "th" : "rd";
          break;
        default:
          string += "th";
      }
      string += " ";
      let month = elems[1];
      switch (month) {
        case "01":
          string += "January";
          break;
        case "02":
          string += "February";
          break;
        case "03":
          string += "March";
          break;
        case "04":
          string += "April";
          break;
        case "05":
          string += "May";
          break;
        case "06":
          string += "June";
          break;
        case "07":
          string += "July";
          break;
        case "08":
          string += "August";
          break;
        case "09":
          string += "September";
          break;
        case "10":
          string += "October";
          break;
        case "11":
          string += "November";
          break;
        case "12":
          string += "December";
          break;
      }
      string += ", " + elems[0];
      return string;
    },
    screenWidth() {
      return window.innerWidth;
    },
  },
  watch: {
    showImage(val) {
      if (val) {
        const $this = this;
        setTimeout(() => {
          let splide = $this.$refs["dialogSlide"];
          splide.splide.on("move", function(index) {
            $this.currentDialogIndex = index;
          });
        }, 500);
      }
    },
  },
  mounted() {
    console.log(this.$route.query.id)
    this.fetchProject();
  },
};
export default main;
</script>
<style scoped>
.slide {
  width: 100%;
  margin: 0 0 2rem 0;
}
.infos {
  max-width: 500px;
}
@media (min-width: 850px) {
  .slide {
    max-width: 50%;
    float: left;
    margin: 0 3rem 1rem 0;
  }
  .infos {
    max-width: 100%;
  }
}
@media (max-width: 850px) {
  .cover {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: 3px solid var(--brandred);
  /* opacity: 1; */
}
.cover {
  width: 100%;
}
.dialog-icon {
  padding: 0.2em;
  border-radius: 50%;
  color: var(--brandred);
  border: 1px solid var(--brandred);
}
.tag-chips *:not(:last-child) {
  margin-right: 0.5em;
}
.arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background: var(--brand);
  border-radius: 50%;
}
</style>
