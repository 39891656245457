import axios from "axios";

const baseUrl =
  "https://us-central1-victor-onwukwe.cloudfunctions.net/app/api/";
// const baseUrl = "http://localhost:3000/api/";

axios.interceptors.request.use(
  function (config) {
    config.baseURL = baseUrl;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
