<template>
  <router-link
    :to="'/projects/' + project.name + '?id=' + project._id"
    style="background-image:none; padding:0 !important;"
  >
    <div class="project elevation-1">
      <img v-show="loaded" :id="project._id" class="image" :alt="project.name" style="width:100%" />
      <div
        v-if="!loaded"
        style="width:100%;margin-top:-2em"
        :style="{ paddingTop: project.type == 'website' ? '50%' : '102%' }"
        class="boilerplate"
      ></div>
      <div class="infos d-flex align-center">
        <div style="width:100%">
          <div
            class="info-content ptl"
            style="text-align:center;font-size:1.5em"
          >
            {{ project.name }}
          </div>
          <div
            class="stl text-uppercase info-content slow font-weight-thin"
            style="text-align:center;font-size:0.85em"
          >
            {{ project.type }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    project: Object,
    index: Number,
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
  mounted() {
    let img = document.getElementById(this.project._id);
    let $this = this;
    img.onload = function() {
      $this.loaded = true;
    };
    let index = this.project.images.findIndex((img) => {
      return img.name.toLowerCase() == "home";
    });
    img.src = this.project.images[index].high;
  },
};
</script>
<style scoped>
.project {
  position: relative;
  overflow: hidden;
}
.project > .infos {
  opacity: 0;
}
.project:hover > .infos {
  opacity: 1;
}
.project .image {
  transition: transform 0.3s ease-in-out;
  margin-bottom: -7px;
}
.project:hover > .image {
  transform: scale(1.1);
}
.info-content {
  transform: translateY(10px);
  transition: all 0.3s ease-out;
  transition-delay: 0.2s;
  opacity: 0;
}
.slow {
  transition: all 0.45s ease-out;
  transition-delay: 0.2s;
}

.project:hover .info-content {
  transform: translateY(0);
  opacity: 1;
}

.infos {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s ease-in-out;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.boilerplate {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: #dddbdd;
}
.boilerplate::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 5s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
