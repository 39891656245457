var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(!_vm.disabled)?_c('div',{class:_vm.bodyClasses,style:({
    backgroundColor: _vm.outlined ? 'transparent' : _vm.color,
    color: !hover
      ? _vm.outlined
        ? _vm.color
        : _vm.dark
          ? 'white'
          : 'var(--brand)'
      : _vm.outlined && _vm.color !== 'white'
        ? 'white'
        : _vm.color == 'white'
          ? 'var(--brand)'
          : _vm.dark
            ? 'white'
            : 'var(--text-primary)',
    border: _vm.outlined ? `1px solid ${_vm.color || 'var(--brand)'}` : 'none',
    borderRadius: _vm.tile ? '0' : '4px',
  }),attrs:{"id":"button-main"}},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[_vm.width ? 'px-0' : '', ..._vm.classes],style:({
      backgroundImage: _vm.outlined
        ? `linear-gradient(${_vm.color}, ${_vm.color})`
        : _vm.color !== 'white'
          ? 'linear-gradient(#E44239,#E44239)'
          : 'linear-gradient(rgba(0,0,0,0.05), rgba(0,0,0,0.05))',
      backgroundSize: hover ? '100% 100%' :
        _vm.animationPosition == 'left' || _vm.animationPosition == 'right'
          ? '0 100%'
          : '100% 0',
      backgroundPosition: _vm.animationPosition,
      width: _vm.width ? _vm.width : '',
      borderRadius: _vm.tile ? '0' : '4px',
      ..._vm.styles,
    }),on:{"click":function($event){return _vm.emitClicked()}}},[_c('div',{class:{ 'hide-slot': _vm.loading },attrs:{"id":"slot"}},[_vm._t("default")],2),_c('div',{staticStyle:{"position":"absolute","top":"0","bottom":"0","left":"0","right":"0","display":"flex"}},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mx-auto my-auto",attrs:{"width":2,"size":20,"indeterminate":"","color":hover ? 'white' : _vm.color}})],1)])]):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }