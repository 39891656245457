<template>
  <div id="home-main">
    <div class="display dynamic-padding">
      <div style="max-width:1200px;width:100%;height:100%">
        <div
          class="write-up mx-auto mt-12 mt-sm-0"
          style="display:flex;align-items:center;flex-direction:column"
        >
          <div class="slide-enter dynamic-text">
            <span class="white--text letter-i rufina">I Love To </span>
            <vue-typer
              :text="texts"
              :eraseStyle="'backspace'"
              :eraseDelay="80"
              :typeDelay="100"
              :preEraseDelay="4000"
              :initialAction="'erasing'"
              :caretAnimation="'smooth'"
              @erased="moveSlider"
              class="rufina"
            ></vue-typer>
          </div>
          <p
            class="white--text mt-4 slide-enter delay-1 display-text"
            style="max-width:750px;text-align:center"
          >
            Hello, I am Victor. I'm pro-dreams, pro-imagination, pro-creativity,
            and pro-fessional. Tell me what you want, when you want it, and how
            you want it...
          </p>

          <div
            class="slide-enter delay-2 d-flex flex-wrap justify-center mx-n4"
          >
            <router-link to="/hire" style="background-image:none;">
              <m-button
                color="white"
                outlined
                tile
                width="150px"
                animationPosition="left"
                class="mx-4"
              >
                <div class="display-text">Hire Me</div>
              </m-button>
            </router-link>
            <router-link to="/about" style="background-image:none;">
              <m-button
                outlined
                tile
                width="150px"
                color="white"
                class="mx-4"
                animationPosition="right"
              >
                <div class="display-text">Learn More</div></m-button
              ></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <img
      v-if="$vuetify.breakpoint.smAndUp"
      class="cover"
      src="../assets/backgrounds/website.jpg"
    />
    <img v-else class="cover" src="../assets/backgrounds/website-low.jpg" />
    <img class="cover" src="../assets/backgrounds/mobile.jpg" />
    <img class="cover" src="../assets/backgrounds/marketing.jpg" />
    <div
      style="position:absolute;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,0.3)"
    ></div>
  </div>
</template>
<script>
import Button from "../components/Button";
import { VueTyper } from "vue-typer";
export default {
  name: "home-display",
  components: {
    "m-button": Button,
    "vue-typer": VueTyper,
  },
  data() {
    return {
      texts: ["Build Web Apps", "Build Mobile Apps", "Promote Brands"],
      images: [],
    };
  },
  methods: {
    moveSlider(erasedString) {
      let formerIndex = this.texts.findIndex((a) => a == erasedString);
      let duration = this.texts[(formerIndex + 1) % 3].length * 200 - 200;
      this.images[formerIndex].style.transitionDuration = duration + "ms";
      this.images[formerIndex].style.transitionDuration = duration + "ms";
      setTimeout(() => {
        this.images[formerIndex].style.opacity = 0;
        this.images[(formerIndex + 1) % 3].style.opacity = 1;
      }, 200);
    },
  },
  mounted() {
    this.images = document.getElementsByClassName("cover");
    this.images[0].style.opacity = 1;
    this.images[0].style.transitionDuration = 3000;
  },
};
</script>
<style scoped>
.display {
  width: 100%;
}
.letter-i {
  font-size: 1.6em;
}
.dynamic-text {
  margin: 0 -0.7em;
}
.cover {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 2.5s ease;
}
.show {
  opacity: 1 !important;
  transition: opacity 3s ease;
}
.slide {
  filter: blur(1px) !important;
}
#home-main {
  position: relative;
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 90vh;
  min-height: calc(var(--vh, 1vh) * 90);
  background-color: white;
  display: flex;
  align-items: center;
}
.home-image {
  max-width: 80px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.write-up {
  max-width: 100%;
  margin: auto auto auto 0;
  position: relative;
  z-index: 1;
}
.display-text {
  font-size: 14px;
}
@media (min-width: 450px) {
  .home-image {
    max-width: 150px;
    display: block;
  }
  .write-up {
    max-width: 100%;
  }
  .letter-i {
    font-size: 2em;
  }
}
@media (min-width: 600px) {
  .letter-i {
    font-size: 2.7em;
  }
  .home-image {
    max-width: 200px;
  }
  .display-text {
    font-size: 15px;
  }
  #home-main {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

@media (min-width: 960px) {
  .home-image {
    max-width: 250px;
  }
  .display-text {
    font-size: 16px;
  }
}
@media (min-width: 1296px) {
  .home-image {
    max-width: 300px;
  }
}

.slide-enter {
  animation: enter 0.5s ease;
  animation-fill-mode: backwards;
}
.delay-1 {
  animation-delay: 0.15s;
}
.delay-2 {
  animation-delay: 0.3s;
}

@keyframes enter {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
