<template>
  <div>
    <div class="d-flex flex-column align-center">
      <div class="d-flex align-center flex-column mb-6">
        <v-icon v-if="$route.name == 'home'" size="2em" color="brand lighten-4"
          class="mb-1">$vuetify.icons.creation</v-icon>
        <h1 class="rubik font-weight-normal" style="font-weight: normal;position:relative;z-index:1"
          v-if="$route.name == 'home'">
          My Works
        </h1>
      </div>

      <div class="d-flex pb-4 flex-wrap justify-center" style="position:relative;z-index:2">
        <a id="all" @click="navigateProject('all')" class="darkblue--text nav">All</a>
        <a id="website" @click="navigateProject('website')" class="darkblue--text nav">Website</a>
        <a id="android-app" @click="navigateProject('android app')" class="darkblue--text nav">Android</a>
        <a id="desktop" @click="navigateProject('desktop')" class="darkblue--text nav">Desktop</a>
        <a id="ios-app" @click="navigateProject('ios app')" class="darkblue--text nav">IOS</a>
      </div>
    </div>
    <div v-if="projects.length > 0 && !loading" class="mt-5 mt-sm-8 mt-md-12">
      <div v-masonry transition-duration="0.2s" item-selector=".proj" gutter="20" id="container">
        <div v-masonry-tile v-for="(project, n) in projects" :key="n" class="proj pa-0">
          <project :index="n" :project="project"></project>
        </div>
      </div>
    </div>
    <div v-else-if="loading" class="d-flex justify-center" style="margin:5em 0">
      <v-progress-circular :value="20" width="3" color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <p class="std" style="text-align:center; margin-top:5em">
        Oops! Nothing yet. Watch out for this space...
      </p>
    </div>

    <!-- My Organizations -->

    <!-- <div class="d-flex flex-column align-center mt-8">
      <div class="d-flex align-center flex-column mb-6">
        <v-icon v-if="$route.name == 'home'" size="2em" color="brand lighten-4"
          class="mb-1">$vuetify.icons.creation</v-icon>
        <h1 class="rubik font-weight-normal" style="font-weight: normal;position:relative;z-index:1"
          v-if="$route.name == 'home'">
          My Organizations
        </h1>
      </div>

      <div class="d-flex pb-4 flex-wrap justify-center" style="position:relative;z-index:2">
        <a id="all" @click="navigateProject('all')" class="darkblue--text nav">All</a>
      </div>
    </div>
    <div v-if="organizations.length > 0 && !fetchingOrganizations" class="mt-5 mt-sm-8 mt-md-12">
      <div v-masonry transition-duration="0.2s" item-selector=".proj" gutter="20" id="container">
        <div v-masonry-tile v-for="(organization, n) in organizations" :key="n" class="proj pa-0">
          <organization :index="n" :organization="organization"></organization>
        </div>
      </div>
    </div>
    <div v-else-if="fetchingOrganizations" class="d-flex justify-center" style="margin:5em 0">
      <v-progress-circular :value="20" width="3" color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <p class="std" style="text-align:center; margin-top:5em">
        Oops! Nothing yet. Watch out for this space...
      </p>
    </div> -->
  </div>
</template>
<script>
import Project from "./Project";

export default {
  components: {
    Project,
  },
  data() {
    return {
      projects: [],
      organizations: [],
      target: null,
      loading: true,
      fetchingOrganizations: true
    };
  },
  methods: {
    async fetchProjects(type) {
      this.loading = true;
      this.projects = await this.$store.dispatch(
        "projects/fetchProjects",
        type
      );
      this.loading = false;
    },
    async fetchOrganizations(type) {
      this.fetchingOrganizations = true;
      this.organizations = await this.$store.dispatch(
        "organizations/fetchOrganizations",
        type
      );
      this.fetchingOrganizations = false;
    },
    async navigateProject(path) {
      this.target.classList.remove("active");
      this.target = document.querySelector("#" + path.replace(" ", "-"));
      this.target.classList.add("active");
      this.projects = [];
      this.fetchProjects(path);
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  mounted() {
    this.fetchProjects("all");
    this.target = document.querySelector("#all");
    this.target.classList.add("active");
  },
};
</script>
<style scoped>
.proj {
  width: 100%;
  margin-bottom: 20px;
  /* padding: 0 0.25em; */
}

.nav {
  background-image: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    linear-gradient(var(--brandred), var(--brandred));
  padding-bottom: 10px;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

@media (min-width: 600px) {
  .proj {
    width: calc(50% - 10px);
  }

  .nav {
    padding-left: 1.2em;
    padding-right: 1.2em;
  }
}

.active {
  background-size: 0 2px, 100% 2px !important;
}
</style>
