const axios = require("axios");

export default {
  namespaced: true,
  state: {
    authenticated: false,
    token: "",
    aboutImage: {},
    portfolioImage: {},
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },
    setAuthenticated: (state, authenticated) => {
      state.authenticated = authenticated;
    },
    setAboutImage: (state, image) => {
      state.aboutImage = image;
    },
    setImage: (state, { name, image }) => {
      state[name] = image;
    },
  },
  getters: {
    authenticated: (state) => state.authenticated,
    token: (state) => state.token,
    aboutImage: (state) => state.aboutImage,
    portfolioImage: (state) => state.portfolioImage,
  },
  actions: {
    async signup({ commit }, payload) {
      try {
        const token = await axios.post(`/users`, payload);
        commit("setAuthenticated", true);
        commit("setToken", token.data);
      } catch (e) {
        console.log(e);
      }
    },
    async login({ commit }, payload) {
      try {
        const result = await axios.post(`/auth`, { ...payload });
        const user = result.data;
        if (user.isAdmin) commit("setAuthenticated", true);

        commit("setToken", user.token);
      } catch (e) {
        console.log(e);
      }
    },
    logout({ commit }) {
      commit("setToken", null);
      commit("setAuthenticated", false);
    },
    async uploadImage(_, payload) {
      try {
        await axios.post(`/users/image`, payload);
      } catch (err) {
        console.log(err);
      }
    },
    async fetchImage({ commit }, type) {
      try {
        const result = await axios.get(`/users/image`, { params: { type } });
        commit("setImage", { image: result.data, name: type + "Image" });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
