<template>
  <div
    style="
  animation: slide-up 0.5s ease;"
  >
    <GmapMap :center="mapCenter" :zoom="15" id="map">
      <GmapMarker
        :position="marker.position"
        :clickable="true"
        :draggable="false"
        :animation="2"
        :title="'My Office'"
        @click="mapCenter = marker.position"
        ref="myMarker"
      />
    </GmapMap>
    <div class="grid" id="contact-form">
      <div>
        <h2>Get In Touch</h2>
        <v-form ref="form" class="mt-1">
          <v-textarea
            class="input-field"
            tile
            outlined
            rows="6"
            no-resize
            @focus="focused.message = true"
            @blur="focused.message = false"
            :rules="[rules.required('message'), rules.minlength(50, 'message')]"
            counter="50"
            validate-on-blur
            v-model="message"
            ><template v-slot:label>
              <div
                class="font-weight-thin"
                :class="{ 'grey--text text--darken-1': !focused.message }"
                :style="{ fontSize: focused.message ? '0.9em' : '0.8em' }"
              >
                Enter Message*
              </div>
            </template>
          </v-textarea>
          <v-row class="mt-n4">
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                class="input-field"
                @focus="focused.name = true"
                @blur="focused.name = false"
                v-model="name"
              >
                <template v-slot:label>
                  <div
                    class="font-weight-thin"
                    :class="{ 'grey--text text--darken-1': !focused.name }"
                    :style="{ fontSize: focused.name ? '0.9em' : '0.8em' }"
                  >
                    Name
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="xs-12" class="mt-n7 mt-sm-0">
              <v-text-field
                outlined
                class="input-field"
                @focus="focused.email = true"
                @blur="focused.email = false"
                :rules="[...rules.email]"
                validate-on-blur
                v-model="email"
              >
                <template v-slot:label>
                  <div
                    class="font-weight-thin"
                    :class="{ 'grey--text text--darken-1': !focused.email }"
                    :style="{ fontSize: focused.email ? '0.9em' : '0.8em' }"
                  >
                    Email*
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            outlined
            class="input-field mt-n4"
            @focus="focused.subject = true"
            @blur="focused.subject = false"
            v-model="subject"
          >
            <template v-slot:label>
              <div
                class="font-weight-thin"
                :class="{ 'grey--text text--darken-1': !focused.subject }"
                :style="{ fontSize: focused.subject ? '0.9em' : '0.8em' }"
              >
                Subject
              </div>
            </template>
          </v-text-field>
          <div>
            <span class="ptd font-weight-light" style="font-size:0.8em"
              ><v-icon color="info">mdi-alert-circle</v-icon>&nbsp;All fields
              below are optional</span
            >
            <v-row>
              <v-col cols="6">
                <v-select
                  :items="[
                    'Maintenance',
                    'Website',
                    'Android App',
                    'IOS App',
                    'Digital Marketing',
                  ]"
                  v-model="services"
                  outlined
                  class="input-field"
                  @focus="focused.services = true"
                  @blur="focused.services = false"
                  multiple
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-thin"
                      :class="{
                        'grey--text text--darken-1': !focused.services,
                      }"
                      :style="{
                        fontSize: focused.services ? '0.9em' : '0.8em',
                      }"
                    >
                      Service(s)
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="[
                    'E-Commerce',
                    'Blog',
                    'Business',
                    'Portfolio',
                    'Entertainment',
                    'Brochure',
                    'Nonprofit',
                    'Infopreneur',
                    'Personal',
                    'Educational',
                    'Wiki',
                    'Portal',
                  ]"
                  v-model="category"
                  outlined
                  class="input-field"
                  @focus="focused.category = true"
                  @blur="focused.category = false"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-thin"
                      :class="{
                        'grey--text text--darken-1': !focused.category,
                      }"
                      :style="{
                        fontSize: focused.category ? '0.9em' : '0.8em',
                      }"
                    >
                      Category
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n7" v-if="type !== 'consult'">
              <v-col cols="6">
                <v-select
                  :items="[
                    'Less than $1000',
                    '$1000 - $2000',
                    '$2000 - $3000',
                    '$3000 - $4,000',
                    'Greater than $4,000',
                  ]"
                  @focus="focused.budget = true"
                  @blur="focused.budget = false"
                  class="input-field"
                  v-model="budget"
                  outlined
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-thin"
                      :class="{ 'grey--text text--darken-1': !focused.budget }"
                      :style="{ fontSize: focused.budget ? '0.9em' : '0.8em' }"
                    >
                      Budget
                    </div>
                  </template>
                </v-select></v-col
              >
              <v-col cols="6">
                <v-select
                  :items="[
                    'Less than 1 month',
                    '1 - 2 months',
                    '2 - 3 months',
                    'More than 3 months',
                  ]"
                  @focus="focused.duration = true"
                  @blur="focused.duration = false"
                  class="input-field"
                  v-model="duration"
                  outlined
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-thin"
                      :class="{
                        'grey--text text--darken-1': !focused.duration,
                      }"
                      :style="{
                        fontSize: focused.duration ? '0.9em' : '0.8em',
                      }"
                    >
                      Duration
                    </div>
                  </template>
                </v-select></v-col
              >
            </v-row>
          </div>
          <m-button
            class="rufina button"
            outlined
            tile
            :animationPosition="'left'"
            :loading="sendingMail"
            :color="'var(--brand)'"
            @click="sendMail()"
            ><div class="rufina">SEND</div></m-button
          >
        </v-form>
      </div>
      <div class="d-flex" style="padding-top:56px">
        <div class="contacts">
          <div class="d-flex align-center">
            <v-icon size="1.4em" color="rgba(0,0,0,0.5)" class="mr-6"
              >$vuetify.icons.home</v-icon
            >
            <div>
              <v-list-item-title class="rufina black--text">
                Port Harcourt, Rivers State
              </v-list-item-title>
              <v-list-item-subtitle class="std" style="font-size:1em"
                >Apamini, Woji 500272</v-list-item-subtitle
              >
            </div>
          </div>
          <div class="mt-10 d-flex align-center">
            <v-icon size="1.45em" color="rgba(0,0,0,0.5)" class="mr-6"
              >$vuetify.icons.phone</v-icon
            >
            <div>
              <v-list-item-title class="rufina black--text">
                +234 813 213 1972
              </v-list-item-title>
              <v-list-item-subtitle class="std" style="font-size:1em"
                >Mon to Sat, 8am to 6pm</v-list-item-subtitle
              >
            </div>
          </div>
          <div class="mt-10 d-flex align-center">
            <v-icon size="1.45em" color="rgba(0,0,0,0.5)" class="mr-6"
              >$vuetify.icons.mail</v-icon
            >
            <div>
              <v-list-item-title class="rufina black--text">
                victoronwukwe25@gmail.com
              </v-list-item-title>
              <v-list-item-subtitle class="std" style="font-size:1em"
                >Send your query at any time!</v-list-item-subtitle
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import Button from "../components/Button";
import Rules from "../methods/Rules";

export default {
  components: {
    "m-button": Button,
  },
  props: {
    type: {
      type: String,
      default: "contact",
    },
  },
  data() {
    return {
      focused: {
        message: false,
        name: false,
        email: false,
        subject: false,
        budget: false,
        duration: false,
        services: false,
        category: false,
      },
      message: "",
      name: "",
      email: "",
      subject: "",
      sendingMail: false,
      color: "red",
      rules: Rules,
      budget: "",
      duration: "",
      services: [],
      category: "",
      marker: {
        position: {
          lat: 4.834030554396136,
          lng: 7.044945875574785,
        },
      },
      mapCenter: {
        lat: 4.834030554396136,
        lng: 7.044945875574785,
      },
    };
  },
  methods: {
    async sendMail() {
      if (!this.$refs.form.validate()) return;
      let message = this.message;

      if (this.budget || this.duration || this.services || this.category)
        message += "\n\n\n";
      if (this.services) message += `Service(s): ${this.services.join(", ")}\n`;
      if (this.category) message += `Category: ${this.category}\n`;
      if (this.type !== "consult") {
        if (this.budget) message += `Budget: ${this.budget}\n`;
        if (this.duration) message += `Duration: ${this.duration}`;
      }

      this.sendingMail = true;
      try {
        init("user_6ISrOCwGjmUxvaecWZlqo");
        await emailjs.send("contact_victoronwukwe25", "template_rbumsza", {
          from_name: this.name || "Anon",
          to_name: "Victor",
          message: message,
          reply_to: this.email,
          subject: this.subject || `New message from ${this.name || "Anon"}`,
        });
        this.sendingMail = false;
        this.successful = true;
        this.$store.commit("snackbar/set", {
          show: true,
          message: "Mail sent successfully",
        });
        this.$refs.form.reset();
        this.sendingMail = false;
        setTimeout(() => {
          this.setInput();
        }, 100);
      } catch (e) {
        this.sendingMail = false;
        this.$store.commit("snackbar/set", {
          show: true,
          message: "Sorry. An error occured",
        });
      }
    },
    analyzeRoute(init) {
      if (this.type == "hire") {
        this.message =
          "Hello Victor, I/we would love to have you work with me/us on my/our project.";
        this.subject = "Call for hire";
        let element = document.getElementById("contact-form");
        let offsetPosition =
          element.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({ top: offsetPosition });
      } else if (this.type == "consult") {
        this.message =
          "Hello Victor, I/we would love to seek your experience for my/our project.";
        this.subject = "Consultation";
        let element = document.getElementById("contact-form");
        let offsetPosition =
          element.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({ top: offsetPosition });
        if (!init)
          setTimeout(() => {
            this.$refs.form.validate();
          }, 50);
      } else if (this.type == "contact" && !init) {
        this.$refs.form.reset();
      }
    },
    setInput() {
      if (this.type == "consult") {
        this.message =
          "Hello Victor, I/we would like to seek your experience for my/our project.";
        this.subject = "Consultation";
      } else if (this.type == "hire") {
        this.message =
          "Hello Victor, I/we would like to have you work with me/us on my/our project.";
        this.subject = "Call For Hire";
      }
    },
  },
  watch: {
    $route: function() {
      this.analyzeRoute(false);
    },
  },
  mounted() {
    this.analyzeRoute(true);
  },
};
</script>
<style scoped>
.input-field {
  border-radius: 0;
}
.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3em;
}
#map {
  width: 100%;
  height: 400px;
  margin-bottom: 3em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
@media (min-width: 600px) {
  #map {
    width: 100%;
    height: 300px;
    margin-bottom: 4em;
  }
}
@media (min-width: 900px) {
  .grid {
    grid-template-columns: 2fr 1fr;
    grid-gap: 2em;
  }
  .contacts {
    margin-left: auto;
  }
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.15);
}

@keyframes pop-out {
  0% {
    box-shadow: inset 0px 3px 10px rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0);
  }
  51% {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  }
}
</style>
